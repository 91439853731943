<template>

  <a-modal width="800px"
           v-model:visible="visible"
           :keyboard="false"
           :centered="true"
           :maskClosable="false"
           :footer="null"
           @cancel="hanldeClose"
           :title="$t('warehouse.select_address')">
    <a-form ref="refForm"
            :rules="rules"
            :model="data">

      <a-form-item v-if="isShowSelectCommonAddress"
                   :label-col="{sm: {span: 4},md: {span: 3},xl: {span: 3},xxl: {span: 3},}"
                   :label="$t('warehouse.common_address')">
        <a-select v-model:value="data.selectedCommonAddress"
                  :loading="dropdownData.commonAddressLoading"
                  @change="changeSelectCommonAddress"
                  class="max-w-330"
                  optionFilterProp="search-key"
                  show-search
                  :allowClear="true"
                  :placeholder="$t('warehouse.selected_common_address')">
          <a-select-option v-for="item in dropdownData.commonAddress"
                           :key="item.id"
                           :value="item.id"
                           :countryId="item.countryId"
                           :provinceId="item.provinceId"
                           :cityId="item.cityId"
                           :countyId="item.countyId"
                           :address="item.address"
                           :address2="item.address2"
                           :postCode="item.postCode"
                           :commonAddressName="item.contactName"
                           :search-key="item.contactName"
                           :title="item.contactName">
            {{item.contactName}}
          </a-select-option>
        </a-select>

        <!-- <a-button class="ml-3"
                  size="small"
                  @click="handleSaveCommonAddress"
                  :loading="dropdownData.buttonSaveCommonAddressLoading"
                  v-if="isShowButtonSaveCommon&&!data.selectedCommonAddress">
          {{$t('warehouse.save_as_common_address')}}
        </a-button>

        <a-button class="ml-3"
                  size="small"
                  :loading="dropdownData.buttonSaveCommonAddressLoading"
                  @click="handleSaveCommonAddress"
                  v-show="isShowButtonSaveCommon&&data.selectedCommonAddress">
          {{$t('warehouse.refresh_address')}}
        </a-button> -->

      </a-form-item>

      <!--  发自--- 暂时留着
        <a-form-item :label-col="{sm: {span: 4},md: {span: 3},xl: {span: 3},xxl: {span:3},}"
                   name="commonAddressName">
        <template #label>
          <span v-if="isShipAddress">{{$t('warehouse.originate')}}</span>
          <span v-else>{{$t('warehouse.destination')}}</span>
        </template>
        <a-input v-model:value="data.commonAddressName"
                 class="max-w-330" />
      </a-form-item> -->

      <a-form-item :label-col="{sm: {span: 4},md: {span: 3},xl: {span: 3},xxl: {span: 3},}"
                   name="sendGoodsAddress"
                   :autoLink="false">
        <template #label>
          <span>{{$t('logistics.destination_address')}}</span>
        </template>
        <a-select v-model:value="data.selectedCountrys"
                  :allowClear="true"
                  show-search
                  optionFilterProp="search-key"
                  :loading="dropdownData.countryLoading"
                  @change="handleChangeCountry"
                  class="mr-2 mb-1"
                  style="max-width:150px;"
                  :placeholder="$t('common.country')">
          <a-select-option v-for="item in dropdownData.countrys"
                           :key="item.id"
                           :title="getLanguageName(item)+'('+item.ioS2+')'"
                           :search-key="item.cnName + item.enName+item.ioS2">
            {{getLanguageName(item)}}({{item.ioS2}})
          </a-select-option>
        </a-select>

        <a-select v-model:value="data.selectedProvinces"
                  @change="handleChangeProvinces"
                  name="selectedProvinces"
                  class="mr-2 mb-1 "
                  style="max-width:150px;"
                  :loading="dropdownData.provincesLoading"
                  :allowClear="true"
                  show-search
                  optionFilterProp="search-key"
                  :placeholder="$t('common.province')">
          <a-select-option v-for="item in dropdownData.provinces"
                           :title="getLanguageName(item)+item.abbrCode"
                           :key="item.id"
                           :search-key="item.cnName + item.enName+item.abbrCode">
            {{getLanguageName(item) + (item.abbrCode?"("+item.abbrCode+")":"")}}
          </a-select-option>
        </a-select>

        <a-select v-model:value="data.selectedCity"
                  @change="handleChangeCity"
                  class="mr-2 mb-1 "
                  style="max-width:150px;"
                  :allowClear="true"
                  show-search
                  :loading="dropdownData.cityLoading"
                  optionFilterProp="search-key"
                  :placeholder="$t('common.city')">
          <a-select-option v-for="item in dropdownData.citys"
                           :title="getLanguageName(item)"
                           :key="item.id"
                           :search-key="item.cnName + item.enName">
            {{getLanguageName(item)}}
          </a-select-option>
        </a-select>

        <a-select v-if="dropdownData.countys.length > 0 && data.selectedCity"
                  v-model:value="data.selectedCounty"
                  class="mr-2 "
                  style="max-width:150px;"
                  :allowClear="true"
                  show-search
                  :loading="dropdownData.countyLoading"
                  optionFilterProp="search-key"
                  :placeholder="$t('common.county')">
          <a-select-option v-for="item in dropdownData.countys"
                           :title="getLanguageName(item)"
                           :key="item.id"
                           :search-key="item.cnName + item.enName">
            {{getLanguageName(item)}}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item name="detailedAddress"
                   :label-col="{sm: {span: 4},md: {span: 3},xl: {span: 3},xxl: {span: 3},}"
                   :label="$t('warehouse.detail_address')">
        <a-input v-model:value="data.detailedAddress"
                 :maxlength="35"
                 class="max-w-330" />
      </a-form-item>

      <a-form-item name="detailedAddress2"
                   :label-col="{sm: {span: 4},md: {span: 3},xl: {span: 3},xxl: {span: 3},}"
                   :label="$t('warehouse.detail_address')+2">
        <a-input v-model:value="data.detailedAddress2"
                 :maxlength="35"
                 class="max-w-330" />
      </a-form-item>

      <a-form-item :label="$t('common.postcode')"
                   :label-col="{sm: {span: 4},md: {span: 3}, xl: {span: 3},xxl: {span: 3},}"
                   name="postCode">
        <a-input v-model:value="data.postCode"
                 class="max-w-330" />
        <a-button :loading="postCodeQueryData.loading"
                  class="ml-3"
                  @click="handlePostCodeQuery"
                  size="small">
          {{$t('waybill.check_the_postcode')}}
        </a-button>
      </a-form-item>

      <a-form-item :label="$t('warehouse.recipients')"
                   :label-col="{sm: {span: 4},md: {span: 3},xl: {span: 3},xxl: {span: 3},}"
                   name="receiptUserName">
        <a-input v-model:value="data.receiptUserName"
                 v-user-name-filter
                 class="max-w-330" />
      </a-form-item>

      <!-- <a-form-item :label="$t('warehouse.phone')"
                   name="contactPhone"
                   :label-col="{sm: {span: 4},md: {span: 3},xl: {span: 3},xxl: {span: 3},}">
        <a-input v-model:value="data.contactPhone"
                 class="max-w-330" />
      </a-form-item> -->
      <a-form-item :label="$t('warehouse.phone')"
                    :label-col="{sm: {span: 4},md: {span: 3},xl: {span: 3},xxl: {span: 3},}"
                    name="contactPhone">
        <a-input-group class="max-w-330" style="display: flex;" compact>
          <a-select
            style="width: 100px;"
            v-model:value="data.phoneCountryId"
            :placeholder="$t('logistics.area_code')"
            :loading="dropdownData.phoneCountryLoading"
            option-label-prop="label"
            optionFilterProp="search-key"
            show-search
            @change="handleCountryCodeChange"
          >
            <a-select-option
              v-for="(item, index) in dropdownData.phoneCountryList"
              :key="index"
              :value="item.id"
              :label="`+${item.telCode}`"
              :search-key="item.ioS2 + item.cnName + item.enName + '+' + item.telCode"
              :title="`+${item.telCode}(${getLanguageName(item)})`"
            >+{{ item.telCode }}({{ getLanguageName(item) }})</a-select-option>
          </a-select>
          <a-input
            style="flex: 1;"
            v-model:value="data.contactPhone"
            :placeholder="$t('warehouse.phone')"
            @change="handleCountryCodeChange"
          />
        </a-input-group>
      </a-form-item>
    </a-form>

    <a-row type="flex"
           justify="center">
      <a-col>
        <a-button @click="hanldeClose">{{$t('common.close')}}</a-button>
      </a-col>
      <a-col class="ml-5">
        <a-button type="primary"
                  @click="hanldeConfirm">
          {{$t('common.confirm')}}
        </a-button>
      </a-col>
    </a-row>
  </a-modal>

  <CPostQuery ref="postCodeQueryRef"
              v-model:visible="postCodeQueryData.visible"
              :postCode="data.postCode"
              @onSelected="handleOnPostQuerySelected" />

</template>

<script>
import { reactive, onMounted, ref, watch } from "vue";
import {
  Row, Col, Input,
  Select, Button, Form, Modal, message
} from "ant-design-vue";
import CPostQuery from "@/views/components/CPostQuery.vue";
import { useI18n } from "vue-i18n/index";
import {
  getCommonAddress, createCommonAddress, updateCommonAddress
} from "@/api/modules/common/index";
import { getGlobalCountrys } from '@/api/modules/common/index';
import { getName,replaceBrackets,verifyMobilePhoneNumber,isValidGenericPhoneNumber } from "@/utils/general";
import { useStore } from "vuex";
import { useNewAddrHelper } from "@/utils/addressHelper"

export default ({
  name: "CAddressModal",
  components: {
    ARow: Row,
    ACol: Col,
    AInput: Input,
    AInputGroup: Input.Group,
    ASelect: Select,
    AButton: Button,
    ASelectOption: Select.Option,
    AFormItem: Form.Item,
    AForm: Form,
    AModal: Modal,
    CPostQuery,
  },
  emits: ["alterAddressConfirm", 'update:visible', 'update:addressData'],
  props: {
    isShowButtonSaveCommon: {
      type: Boolean,
      default: true,
      required: false
    },
    isShowSelectCommonAddress: {
      type: Boolean,
      default: false,
      required: false
    },
    isShowOriginate: {
      type: Boolean,
      default: true,
      required: false
    },
    isShipAddress: {//true:文字是发货,false:文字收货
      type: Boolean,
      default: true,
      required: false
    },
    visible: {
      type: Boolean,
      default: false,
    },
    addressData: {
      type: Object,
      default: (props) => ({
        selectedCommonAddress: null,
        commonAddressName: "",//发自\发至
        selectedCountrys: null,
        selectedProvinces: null,
        selectedCity: null,
        selectedCounty: null,
        detailedAddress: null,
        detailedAddress2: null,
        postCode: null,
        contactPhone: null,
        phoneCountryId:null,
        areaCode: null,
        receiptUserName: null,
        loading: false,
      }),
      required: false
    },
  },
  setup (props, { emit }) {

    const refForm = ref();
    const postCodeQueryRef = ref(null);
    const vueI18n = useI18n({ useScope: "global" });
    const { getters } = useStore();
    const addrHelper = useNewAddrHelper();

    const dropdownData = reactive({
      buttonSaveCommonAddressLoading: false,
      commonAddress: [],
      commonAddressLoading: false,
      countrys: [],
      countryLoading: false,
      provinces: [],
      provincesLoading: false,
      citys: [],
      cityLoading: false,
      countys: [],
      countyLoading: false,
      phoneCountryList: [],
      phoneCountryLoading:false,
    })

    const data = reactive({});

    const postCodeQueryData = reactive({});

    watch(() => props.addressData, (newV, oldV) => {
      Object.assign(data, newV || {});
    }, {
      immediate: true, deep: true
    });

    watch(() => props.visible, (newV, oldV) => {
      if (newV) {
        refForm?.value?.clearValidate();
      }
    });

    watch(() => {
      return [
        props.addressData?.selectedCountrys,
        props.addressData?.selectedProvinces,
        props.addressData?.selectedCity
      ].join("-")
    }, (newV, oldV) => {
      funcGetProvinces();
    })
    watch(()=>data.phoneCountryId,(newVal)=>{
      var matchedCountry = dropdownData.phoneCountryList?.find(x=>x.id == newVal);
      data.areaCode = matchedCountry?.telCode;
    },{
      immediate: true
    })
    const handlePostCodeQuery = () => {
      postCodeQueryData.visible = true;
      if (postCodeQueryRef && postCodeQueryRef.value && postCodeQueryRef.value.handleQuery) {
        postCodeQueryRef.value.handleQuery();
      }
    }

    const handleOnPostQuerySelected = (record) => {
      if (record) {
        data.selectedCountrys = record.countryId;
        data.selectedProvinces = record.provinceId;
        data.selectedCity = record.cityId;
        data.selectedCounty = record.countyId;
        if (record.address) {
          data.detailedAddress = record.address;
        }
        data.postCode = record.postCode;
        refForm.value?.clearValidate();
        funcGetCountrys();
      }
    }

    const handleSaveCommonAddress = () => {
      refForm.value
        .validate()
        .then(() => {
          let par = {
            "id": data.selectedCommonAddress,
            "contactName": data.commonAddressName,//发自
            "countryId": data.selectedCountrys,
            "provinceId": data.selectedProvinces,
            "cityId": data.selectedCity,
            "countyId": data.selectedCounty,
            "address": data.detailedAddress,
            "address2": data.detailedAddress2,
            "postCode": data.postCode
          }

          dropdownData.buttonSaveCommonAddressLoading = true;
          if (par.id) {
            return updateCommonAddress(par).then(() => {
              message.success(vueI18n.t("common.succeed"));
              hanldeGetCommonAddress();
            })
          } else {
            return createCommonAddress(par).then((res) => {
              message.success(vueI18n.t("common.succeed"));
              hanldeGetCommonAddress().then(() => {
                data.selectedCommonAddress = res.result;
              });
            })
          }
        })
        .catch(error => {
          dropdownData.buttonSaveCommonAddressLoading = false;
        }).finally(() => {
          dropdownData.buttonSaveCommonAddressLoading = false;
        });;
    };

    const hanldeGetCommonAddress = async () => {
      dropdownData.commonAddressLoading = true;
      getCommonAddress().then(({ result }) => {
        if (result) {
          dropdownData.commonAddress = result;
        }
        dropdownData.commonAddressLoading = false;
      }).catch(() => {
        dropdownData.commonAddressLoading = false;
      })
    }

    const changeSelectCommonAddress = (value, option) => {
      if (!value) {
        return
      }
      data.commonAddressName = option.commonAddressName ?? "";
      data.selectedCountrys = option.countryId ?? "";
      data.selectedProvinces = option.provinceId ?? "";
      data.selectedCity = option.cityId ?? "";
      data.selectedCounty = option.countyId ?? "";
      handleChangeCountry();

      data.detailedAddress = option.address ?? "";
      data.detailedAddress2 = option.address2 ?? "";
      data.postCode = option.postCode ?? "";
      refForm.value?.clearValidate();
    }

    const handleChangeCountry = async () => {
      funcGetProvinces();
    }

    const funcGetCountrys = async () => {
      dropdownData.countryLoading = true;
      addrHelper.getCountries()
        .then(res => {
          dropdownData.countrys = res;
        }).finally(() => {
          dropdownData.countryLoading = false;
          if (data.selectedCountrys
            && dropdownData.countrys.findIndex(x => x.id == data.selectedCountrys) == -1
          ) {
            data.selectedCountrys = null;
          }
          funcGetProvinces();
        });
    }

    const funcGetProvinces = () => {
      dropdownData.provincesLoading = true;
      addrHelper.getProvinces(data.selectedCountrys)
        .then(res => {
          dropdownData.provinces = res;
        }).finally(() => {
          dropdownData.provincesLoading = false;
          if (data.selectedProvinces
            && dropdownData.provinces.findIndex(x => x.id == data.selectedProvinces) == -1
          ) {
            data.selectedProvinces = null;
          }
          funcGetCitys();
        });
    }

    const handleChangeProvinces = async () => {
      funcGetCitys();
    }

    const funcGetCitys = async () => {
      dropdownData.cityLoading = true;
      await addrHelper.getCities(data.selectedProvinces)
        .then(res => {
          dropdownData.citys = res ?? [];
        }).finally(() => {
          dropdownData.cityLoading = false;
          if (data.selectedCity
            && dropdownData.citys.findIndex(x => x.id == data.selectedCity) == -1
          ) {
            data.selectedCity = null;
          }
          funcGetCountys();
        });
    }

    const handleChangeCity = async () => {
      funcGetCountys();

      //解决先验证了,然后选完地址后还未清除错误消息的情况,只验证到城市
      if (
        data.selectedProvinces
        &&
        (dropdownData.citys.length == 0 || data.selectedCity)
      ) {
        refForm.value?.clearValidate('sendGoodsAddress');
      }
    }

    const funcGetCountys = async () => {
      dropdownData.countyLoading = true;
      await addrHelper.getCounties(data.selectedCity)
        .then(res => {
          dropdownData.countys = res ?? [];
        }).finally(() => {
          dropdownData.countyLoading = false;
          if (data.selectedCounty
            && dropdownData.countys.findIndex(x => x.id == data.selectedCounty) == -1
          ) {
            data.selectedCounty = null;
          }
        });
    }

    
    const getPhoneCountriesList = () => {
      dropdownData.phoneCountryLoading = true
      getGlobalCountrys().then(({result}) => {
        dropdownData.phoneCountryList = result
      }).catch(() => {}).finally(() => {
        dropdownData.phoneCountryLoading = false
      })
    }

    const checkPhoneNumber = () => {
      data.contactPhone = replaceBrackets(data.contactPhone)
    }

    const handleCountryCodeChange = () => {
      checkPhoneNumber()
      if(refForm.value){
      refForm.value.validateFields(["contactPhone"])
      }
    }

    const getLanguageName = (item) => {
      return getName(item, getters.language);
    }

    let validateAddress = async (rule, value) => {

      if (dropdownData.citys?.length > 0 && !data.selectedCity) {
        return Promise.reject();
      }

      if (dropdownData.provinces?.length > 0 && !data.selectedProvinces) {
        return Promise.reject();
      }

      if (!data.selectedCountrys) {
        return Promise.reject();
      }
      return Promise.resolve();
    };

    //获得地址信息(包括id,中英文),用于组合展示.
    const getAddressInfo = () => {
      let address = {
        countryId: null,
        countryCnName: null,
        countryEnName: null,
        ioS2: null,
        provinceId: null,
        provinceCnName: null,
        provinceEnName: null,
        abbrCode: null,//省份简码
        cityId: null,
        cityCnName: null,
        cityEnName: null,
        countyId: null,
        countyCnName: null,
        countyEnName: null,
      };
      address.address = data.detailedAddress;
      address.address2 = data.detailedAddress2;
      address.postCode = data.postCode;
      address.name = data.commonAddressName;
      if (data.selectedCountrys && dropdownData.countrys) {
        let country = dropdownData.countrys.find(x => x.id == data.selectedCountrys);
        address.countryId = country.id;
        address.countryCnName = country.cnName;
        address.countryEnName = country.enName;
        address.ioS2 = country.ioS2;

        if (data.selectedProvinces && dropdownData.provinces) {
          let province = dropdownData.provinces.find(x => x.id == data.selectedProvinces);
          address.provinceId = province.id;
          address.provinceCnName = province.cnName;
          address.provinceEnName = province.enName;
          address.abbrCode = province.abbrCode;
          if (data.selectedCity && dropdownData.citys) {
            let city = dropdownData.citys.find(x => x.id == data.selectedCity);
            address.cityId = city.id;
            address.cityCnName = city.cnName;
            address.cityEnName = city.enName;
            if (data.selectedCounty && dropdownData.countys) {
              let county = dropdownData.countys.find(x => x.id == data.selectedCounty);
              address.countyId = county.id;
              address.countyCnName = county.cnName;
              address.countyEnName = county.enName;
            }
          }
        }
      }
      Object.assign(data, address);
      return data;
    }

    const hanldeClose = () => {
      data.selectedCountrys = null;
      data.selectedCommonAddress = null;
      data.selectedProvinces = null;
      data.selectedCity = null;
      data.selectedCounty = null;
      refForm.value.resetFields();
      emit("update:visible", false);
      emit("update:addressData", data);
    }

    const hanldeConfirm = () => {
      refForm.value.validate().then(() => {
        emit("alterAddressConfirm", Object.assign({}, getAddressInfo()));
        emit("update:visible", false);
        emit("update:addressData", data);
      }).catch(() => { })
    }

    const rules = {
      sendGoodsAddress: [{
        required: true,
        validator: validateAddress,
        whitespace: true,
        trigger: ['change', 'blur'],
        message: () => {
          // return vueI18n.t("common.p0_is_not_complete", [vueI18n.t("warehouse.send_goods_address")])
          return vueI18n.t("common.p0_is_not_complete", [vueI18n.t("warehouse.address")])
        }
      }],
      detailedAddress: [{
        required: true,
        whitespace: true,
        trigger: ['change', 'blur'],
        validator: (rule, value) => {
          if (!value) {
            return Promise.reject(vueI18n.t("common.p0_is_required", [vueI18n.t("warehouse.detail_address")]));
          }
          if (value && value?.length > 35) {
            return Promise.reject(vueI18n.t("common.the_input_length_is_too_long"));
          }

          return Promise.resolve();
        },
      }],
      detailedAddress2: [{
        required: false,
        trigger: ['change', 'blur'],
        validator: (rule, value) => {
          if (value && value?.length > 35) {
            return Promise.reject(vueI18n.t("common.the_input_length_is_too_long"));
          }
          return Promise.resolve();
        },
      }],
      postCode: [{
        required: true,
        whitespace: true,
        trigger: ['change', 'blur'],
        validator: (rule, value) => {
          if (!value||value.trim().length == 0) {
            return Promise.reject(vueI18n.t("common.p0_is_required", [vueI18n.t("common.postcode")]));
          }

          if (value && value?.trim().length < 5) {
            return Promise.reject(vueI18n.t("common.p0_please_enter_at_least_p1_digits", [vueI18n.t("common.postcode"),5]));
          }
          return Promise.resolve();
        },
      }],

      commonAddressName: [{
        required: true,
        whitespace: true,
        trigger: ['change', 'blur'],
        message: () => {
          if (props.isShipAddress) {
            return vueI18n.t("common.p0_is_required", [vueI18n.t("warehouse.originate")])
          } else {
            return vueI18n.t("common.p0_is_required", [vueI18n.t("warehouse.destination")])
          }
        }
      }],
      receiptUserName: [{
        required: true,
        whitespace: true,
        message: () => vueI18n.t("common.p0_is_required", [vueI18n.t("warehouse.recipients")])
      }, {
        trigger: ['change', 'blur'],
        pattern: /^.{1,35}$/,
        message: vueI18n.t("common.p0_up_to_p1_characters", [vueI18n.t("logistics.recipients"), 35])
      }],
      contactPhone: [{
        type: String,
        required: true,
        whitespace: true,
        trigger: ['change', 'blur'],
        validator: (rule, value) => {
          if (!data.phoneCountryId) {
            return Promise.reject(vueI18n.t("common.p0_is_required", [vueI18n.t("logistics.area_code")]));
          }
          if (!value) {
            return Promise.reject(vueI18n.t("common.p0_is_required", [vueI18n.t("warehouse.phone")]));
          }
          
          let selectCountry = dropdownData.phoneCountryList.find(item => item.id === data.phoneCountryId);
          let countryIos2Code = selectCountry?.ioS2 ?? null;
          if (verifyMobilePhoneNumber(countryIos2Code, (selectCountry?.telCode ?? '') + value)) {
            return Promise.resolve();
          } else {
            return Promise.reject(vueI18n.t("common.p0_incorrect_format", [vueI18n.t("warehouse.phone")]));
          }
          // if (!isValidGenericPhoneNumber(value)) {
          //   return Promise.reject(vueI18n.t("common.p0_incorrect_format", [vueI18n.t("warehouse.phone")]));
          // }
          // return Promise.resolve();
        },
      }],
    };

    onMounted(async () => {
      if (props.isShowSelectCommonAddress) {
        hanldeGetCommonAddress();
      }
      funcGetCountrys();
      getPhoneCountriesList();
    });

    return {
      refForm,
      dropdownData,
      data,
      rules,
      props,
      postCodeQueryRef,
      postCodeQueryData,

      handleChangeCountry,
      handleChangeProvinces,
      handleChangeCity,
      getLanguageName,
      changeSelectCommonAddress,
      handleSaveCommonAddress,
      funcGetProvinces,
      funcGetCitys,
      funcGetCountys,
      getAddressInfo,
      handlePostCodeQuery,
      handleOnPostQuerySelected,
      hanldeConfirm,
      hanldeClose,
      handleCountryCodeChange
    }
  }
})
</script>
<style lang="less" scoped>
@media (max-width: 450px) {
  .input-w {
    width: 220px;
  }
}
@media (min-width: 451px) {
  .input-w {
    width: 350px;
  }
}
</style>