<template>
  <Content>
    <template #contentTitle>
      {{ $t("menu.consignment_order_bulk_import") }}
    </template>
    <template v-slot:contentBody="wrap">
      <div class="p-2">
        <p>
          1. {{ $t("warehouse.please_first")
          }}<a-button
            type="link"
            size="small"
            :loading="data.downloadLoading"
            @click="hanldeDownloadTemplateFile"
            >{{ $t("warehouse.download_template") }}</a-button
          >
          ,{{ $t("warehouse.then_follow_the_prompts_to_complete") }}
        </p>
        <p>2. {{ $t("warehouse.consignment_order_bulk_import_text2") }}</p>
        <p>3. {{ $t("warehouse.consignment_order_bulk_import_text3") }}</p>
        <p>4. {{ $t("warehouse.consignment_order_bulk_import_text4") }}</p>
      </div>
      <div class="p-2" v-show="!data.listVisible">
        <a-row>
          <a-col>
            <a-upload
              :customRequest="handleParseFile"
              accept=".xlsx"
              :showUploadList="false"
            >
              <a-button :loading="data.loading">
                <upload-outlined></upload-outlined>
                {{ $t("warehouse.select_import_file") }}
              </a-button>
            </a-upload>
          </a-col>
        </a-row>
      </div>

      <a-card v-show="data.listVisible">
        <a-table
          :columns="columnsImport"
          :loading="data.loading"
          :data-source="data.list"
          :defaultExpandAllRows="true"
          size="small"
          :pagination="false"
          :rowKey="
            (record, index) => {
              return index;
            }
          "
        >
          <template #relationOrderNo="{ record }">
            <div>
              <span v-if="record?.relationOrderNo">
                {{ record?.relationOrderNo }}
              </span>
              <span v-else>-</span>
            </div>
            <a-popover placement="top" v-if="!record.isNormal">
              <template #content>
                <div style="max-width: 400px">
                  <a-descriptions :title="$t('warehouse.data_error')">
                  </a-descriptions>
                  <a-descriptions
                    :column="1"
                    v-if="record?.errObjs && record?.errObjs.length > 0"
                  >
                    <a-descriptions-item
                      v-for="(errObj, index) in record?.errObjs"
                      :key="index"
                    >
                        <a-row>
                        <a-col>
                          {{index+1}}.
                        </a-col>
                        <a-col>
                            {{ $t(getErrorMsgKey(errObj.errCode)) }}
                            <span v-if="errObj?.fields && errObj?.fields.length > 0"
                              >(</span
                            >
                            <template
                              v-if="errObj?.fields && errObj?.fields.length > 0"
                            >
                              <span v-for="(field, i) in errObj?.fields" :key="i">
                                {{ field }}
                                <span v-if="i != errObj?.fields?.length - 1">,</span>
                              </span>
                            </template>
                            <span v-if="errObj?.fields && errObj?.fields.length > 0"
                              >)</span
                            >
                            <div v-if="errObj.msg">
                              <small class="text-grey">{{errObj.msg}}</small>
                            </div>
                       </a-col>
                      </a-row>
                    </a-descriptions-item>
                  </a-descriptions>
                </div>
              </template>

              <div class="text-error">
                <span>{{ $t("warehouse.data_error") }}</span>
                <span class="ml-1 mr-1">
                  <QuestionCircleOutlined />
                </span>
              </div>
            </a-popover>
            <div class="text-error" v-else-if="record.isShowProductError">
                <span>{{ $t("warehouse.product_error") }}</span>
            </div>
          </template>

          <template #saleSourceName="{ record }">
            <div>
              <span v-if="record?.saleSourceName">
                {{ record?.saleSourceName }}
              </span>
              <span v-else>-</span>
            </div>
          </template>

          <template #warehouseNo="{ record }">
            <div>
              <span v-if="record?.warehouseNo">
                {{ record?.warehouseNo }}
              </span>
              <span v-else>-</span>
            </div>
          </template>

          <template #recipientsAndPhone="{ record }">
            <div
              v-if="
                record?.receiveAddressData?.receiptUserName ||
                record?.receiveAddressData?.contactPhone
              "
            >
              <div v-if="record?.receiveAddressData?.receiptUserName">
                {{ record?.receiveAddressData?.receiptUserName }}
              </div>
              <div v-if="record?.receiveAddressData?.contactPhone">
                  {{record?.receiveAddressData?.telCode?("+"+record?.receiveAddressData?.telCode):""}} {{ record?.receiveAddressData?.contactPhone }}
              </div>
            </div>
            <div v-else>-</div>
          </template>

          <template #destination_address="{ record }">
            <div v-if="record?.receiveAddressData">
              {{ getAddress(record?.receiveAddressData) }}
              <div>
                <a  @click="hanldeShowAddressModal(record)">
                  <EditOutlined />
                </a>
              </div>
            </div>
            <div v-else>-</div>
          </template>

          <!-- 运输方案 -->
          <template #transport_scheme="{ record }">
            <div style="min-width: 200px">
              <!-- 第三方面单 -->
              <div
                class="mb-2"
                v-if="
                  record.agentOrderLogisticsType == logisticsTypeEnum.thirdparty
                "
              >
                {{ $t("warehouse.third_side_list") }}:
                <div>
                  <a-select
                    v-model:value="record.otherSurface.deliveryType"
                    size="small"
                    style="width: 150px"
                    optionFilterProp="search-key"
                    show-search
                    :placeholder="$t('warehouse.please_select_delivery_way')"
                  >
                    <a-select-option
                      v-for="item in deliveryWayEnum"
                      :key="item"
                      :title="$t($enumLangkey('deliveryWay', item))"
                      :search-key="$t($enumLangkey('deliveryWay', item))"
                      :value="item"
                    >
                      {{ $t($enumLangkey("deliveryWay", item)) }}
                    </a-select-option>
                  </a-select>
                </div>
                <div class="mt-2">
                  <CustomsUpload
                    :functionModule="9"
                    accept=".png,.jpg,.jpeg,.gif,.bmp,.pdf"
                    v-model:fileUrl="record.otherSurface.surfaceUrl"
                  ></CustomsUpload>
                </div>
              </div>
              <!-- 物流方案 模板 -->
              <div
                v-else-if="
                  record.agentOrderLogisticsType ==
                    logisticsTypeEnum.seLogistics &&
                  record.logistic.logisticsBaseInfo.feeCalculationMethod ==
                    feeCalculationMethodEnum.template
                "
              >
                <span>{{ $t("logistics.se_logistics") }}:</span>
                {{ record?.logistic?.logisticsBaseInfo?.name ?? "-" }}
                <div style="color: RGB(128, 128, 128)">
                  <small
                    >[ {{ $t("logistics.delivery_way") }}:
                    <span
                      v-if="record?.logistic?.logisticsBaseInfo?.deliveryType"
                      >{{
                        $t(
                          $enumLangkey(
                            "deliveryWay",
                            record?.logistic?.logisticsBaseInfo?.deliveryType
                          )
                        )
                      }};</span
                    >
                    <span v-else>-</span>
                    {{ $t("logistics.selected_service") }}:
                    <template
                      v-if="
                        record.logistic?.logisticsRegionInfo?.isChooseDdp ||
                        record.logistic?.logisticsRegionInfo?.isChooseSign
                      "
                    >
                      <span
                        v-if="record.logistic?.logisticsRegionInfo?.isChooseDdp"
                      >
                        DDP<span
                          v-if="
                            record.logistic?.logisticsRegionInfo?.isChooseSign
                          "
                          >,</span
                        >
                      </span>

                      <span
                        v-if="
                          record.logistic?.logisticsRegionInfo?.signatureType
                        "
                      >
                      {{
                        $t(
                          $enumLangkey(
                            "signatureType",
                            record.logistic?.logisticsRegionInfo?.signatureType
                          )
                        )
                      }}
                      </span>
                    </template>
                    <span v-else>-</span>]
                  </small>
                </div>
                <div v-if="record.logistic?.logisticsRegionInfo?.isVatRequired">
                  <span style="color: red">*</span>
                  <label> VAT: </label>
                  <a-input
                    v-model:value="record.logistic.logisticsRegionInfo.vatCode"
                    style="max-width: 150px"
                    size="small"
                  />
                </div>

                <a
                  @click="hanldeShowLogisticsModal(record)"
                  ><EditOutlined /></a
                >
              </div>
              <!-- 物流方案 卡车 -->
              <div v-else>
                <span>{{ $t("logistics.logistics_scheme") }}:</span>
                {{ record?.logistic?.logisticsBaseInfo?.name ?? "-" }}
                <div style="color: RGB(128, 128, 128)">
                  <small>
                    {{ $t("logistics.delivery_way") }}:
                    <span
                      v-if="record?.logistic?.logisticsBaseInfo?.deliveryType"
                      >{{
                        $t(
                          $enumLangkey(
                            "deliveryWay",
                            record?.logistic?.logisticsBaseInfo?.deliveryType
                          )
                        )
                      }}
                      ;
                    </span>
                    <span v-else>-</span>
                    <div>
                      <span>{{ $t("logistics.quote") }}: </span>
                      <span
                        v-if="
                          record?.logistic?.logisticsBaseInfo?.truckBaseInfo
                            ?.quoteDetailId
                        "
                      >
                        {{
                          record?.logistic?.logisticsBaseInfo?.truckBaseInfo
                            ?.carrierName
                        }}
                        [{{
                          $filters.formatCurrencyAmount(
                            record?.logistic?.logisticsBaseInfo?.truckBaseInfo
                              ?.currencySymbol,
                            record?.logistic?.logisticsBaseInfo?.truckBaseInfo
                              ?.totalCharge,
                            false
                          )
                        }}
                        ,
                        {{
                          record?.logistic?.logisticsBaseInfo?.truckBaseInfo
                            ?.transitTime
                        }}
                        day(s)]
                      </span>
                      <span v-else> - </span>
                    </div>
                  </small>
                </div>
                <a-button
                  type="link"
                  @click="hanldeShowLogisticsModal(record)"
                  >{{ $t("account.modify") }}</a-button
                >
              </div>
            </div>
          </template>

          <!-- 包装材料 -->
          <template #pack_material="{ record }">
            <a-tabs
              style="max-width: 290px"
              @change="hanldeChangeMaterialType(record)"
              v-model:activeKey="record.packMaterialType"
              v-if="record.warehouseId"
            >
              <a-tab-pane
                :key="packMaterialTypeEnum.outPacking"
                :tab="$t('warehouse.additional_packing_material')"
                force-render
              >
                <div>
                  <a-select
                  size="small"
                    v-model:value="record.packMaterialConfig.id"
                    @change="handelChangeSelectPackagingType(record)"
                    :allowClear="true"
                    :placeholder="
                      $t(
                        'warehouse.when_the_total_number_of_products_is_greater_than_1_this_option_is_mandatory'
                      )
                    "
                    style="width: 290px"
                  >
                    <a-select-option
                      v-for="item in getPackingMaterials(record.warehouseId)"
                      :key="item.key"
                      :value="item.id"
                      :title="
                        item.name +
                        '-' +
                        getText(item) +
                        (!item.length || !item.width || !item.width
                          ? ''
                          : '-' +
                            item.length +
                            'x' +
                            item.width +
                            'x' +
                            item.height +
                            'cm')
                      "
                    >
                      {{ item.name }}-{{ getText(item) }}
                      {{
                        !item.length || !item.width || !item.width
                          ? ""
                          : "-" +
                            item.length +
                            "x" +
                            item.width +
                            "x" +
                            item.height +
                            "cm"
                      }}
                    </a-select-option>
                  </a-select>
                </div>
              </a-tab-pane>
              <a-tab-pane :key="packMaterialTypeEnum.mergePackaging">
                <template #tab>
                  {{
                    $t(
                      $enumLangkey(
                        "packMaterialType",
                        packMaterialTypeEnum.mergePackaging
                      )
                    )
                  }}
                  <CTooltip :title="$t('warehouse.merge_packaging_tips')" />
                </template>
                <strong v-if="record.products.length>1">
                  {{$t("warehouse.merge_packaging_has_been_selected")}}
                </strong>
                  <span  v-else class="text-warning">
                  {{$t("warehouse.merge_packaging_not_supported")}}
                  </span>
              </a-tab-pane>
            </a-tabs>
          </template>

          <template #delete="{ record, index }">
            <a-popconfirm
              :title="
                $t('common.Are_you_sure_to_delete_p0', [
                  $t('logistics.order_info'),
                ])
              "
              :ok-text="$t('common.confirm')"
              :cancel-text="$t('common.cancel')"
              @confirm="hanldeDeleteData(index)"
            >
              <a-button type="primary" ghost size="small">
                {{ $t("common.delete") }}
              </a-button>
            </a-popconfirm>
          </template>

          <!-- 嵌套表格 -->
          <template #expandedRowRender="{ record }">
            <a-table
              :columns="innerColumnsImport"
              size="small"
              :data-source="record.products"
              :pagination="false"
              :rowKey="
                (record, index) => {
                  return index;
                }
              "
            >
              <template #product="{ record }">
                <a-space v-if="record?.imgUrl || record?.name || record?.no">
                  <div v-if="record?.imgUrl" class="table-list-img-common">
                    <c-image :src="record?.imgUrl"></c-image>
                  </div>
                  <div>
                    <div>{{ record?.name }}</div>
                    <div>{{ record?.no }}</div>
                  </div>
                </a-space>
                <span v-else>-</span>
              </template>

              <template #seSku="{ record }">
                <div v-if="record?.seSku">
                  {{ record?.seSku }}
                </div>
                <div v-else>-</div>
                <div>
                  <a
                    @click="handleShowModalProduct(record)"
                    ><EditOutlined /></a
                  >
                </div>
              </template>

              <template #outCount="{ record }">
                <div v-if="record?.outCount">
                  {{ record?.outCount }}
                </div>
                <div v-else>-</div>
              </template>

              <template #errorMsg="{ record }">
                <a-popover placement="top" v-if="!record.isNormal">
                  <template #content>
                    <div style="max-width: 400px">
                      <a-descriptions :title="$t('warehouse.product_error')">
                      </a-descriptions>

                      <a-descriptions
                        :column="1"
                        v-if="record?.errObjs && record?.errObjs.length > 0"
                      >
                        <a-descriptions-item
                          v-for="(errObj, index) in record?.errObjs"
                          :key="index"
                        >
                          <a-row>
                        <a-col>
                          {{index+1}}.
                        </a-col>
                        <a-col>
                          {{ $t(getErrorMsgKey(errObj.errCode)) }}
                          <span
                            v-if="errObj?.fields && errObj?.fields.length > 0"
                            >(</span
                          >
                          <template
                            v-if="errObj?.fields && errObj?.fields.length > 0"
                          >
                            <span v-for="(field, i) in errObj?.fields" :key="i">
                              {{ field }}
                              <span v-if="i != errObj?.fields?.length - 1"
                                >,</span
                              >
                            </span>
                          </template>
                          <span
                            v-if="errObj?.fields && errObj?.fields.length > 0"
                            >)</span
                          >
                          <div v-if="errObj.msg">
                              <small class="text-grey">{{errObj.msg}}</small>
                            </div>
                       </a-col>
                      </a-row>
                        </a-descriptions-item>
                      </a-descriptions>
                    </div>
                  </template>

                  <div class="text-error">
                    <span>{{ $t("warehouse.product_error") }}</span>
                    <span class="ml-1 mr-1">
                      <QuestionCircleOutlined />
                    </span>
                  </div>
                </a-popover>
                <span v-else style="color: RGB(128, 128, 128)">{{$t('common.nothing')}}</span>
              </template>

              <template #deleteProduct="{ record, index }">
                <a-popconfirm
                  :title="
                    $t('common.Are_you_sure_to_delete_p0', [
                      $t('warehouse.current_product_information'),
                    ])
                  "
                  :ok-text="$t('common.confirm')"
                  :cancel-text="$t('common.cancel')"
                  @confirm="hanldeDeleteProduct(record, index)"
                >
                  <a-button type="primary" ghost size="small">
                    {{ $t("common.delete") }}
                  </a-button>
                </a-popconfirm>
              </template>
            </a-table>
          </template>
        </a-table>

        <a-row type="flex" justify="end">
          <a-col>
            {{
              $t(
                "warehouse.note_data_can_only_be_imported_if_all_data_is_correct"
              )
            }}
          </a-col>
        </a-row>
        <a-row type="flex" justify="end" class="mt-2">
          <a-col>
            <a-button @click="data.listVisible = false" :loading="data.loading">
              {{ $t("common.cancel") }}
            </a-button>

            <a-button
              class="ml-5"
              type="primary"
              :disabled="funcWhetherCanImport()"
              :loading="data.loading"
              @click="hanldeShowImporMessage"
            >
              {{ $t("common.import") }}
            </a-button>
          </a-col>
        </a-row>
      </a-card>

      <!-- 重新选择物流方案 -->
      <a-modal
        v-model:visible="logistics.visible"
        :title="$t('logistics.logistics_scheme')"
        :footer="null"
        :maskClosable="false"
        :centered="true"
        :width="1400"
        :confirm-loading="logistics.loading"
      >
        <div>
          <a-tabs
            v-model:activeKey="logistics.calculationMethod"
            v-if="logistics.isShowLogisticsTabs"
            @change="handleChangeLogisticsTab"
          >
            <a-tab-pane
              :key="feeCalculationMethodEnum.template"
              :tab="$t('logistics.logistics')"
            ></a-tab-pane>
            <a-tab-pane
              :key="feeCalculationMethodEnum.truckApi"
              :tab="$t('logistics.truck')"
            ></a-tab-pane>
          </a-tabs>
          <a-table
            :columns="logistics?.logisticsSchemeExhibitionColumns"
            :data-source="logistics?.logisticsSchemeExhibitionList"
            :pagination="false"
            size="small"
            :scroll="{ x: true, y: true }"
            :loading="logistics.loading"
            :locale="{
              emptyText: $t(
                'logistics.not_currently_available_logistics_scheme'
              ),
            }"
          >
            <template #option v-if="!logistics.selectedLogisticsSchemeId">
            </template>
            <template #logisticsScheme>
              <span>
                {{ $t("warehouse.logistics_scheme") }} / {{ $t("warehouse.logistics_code") }}
              </span>
            </template>

            <template #logisticsWay>
              <span>
                {{ $t("warehouse.logistics_type") }}
              </span>
            </template>

            <template #deliveryWay>
              <span>
                {{ $t("warehouse.delivery_way") }}
              </span>
            </template>

            <template #referenceAging>
              <span>
                {{ $t("warehouse.reference_aging") }}
              </span>
            </template>
            <template #freights>
              <span>
                {{ $t("warehouse.freight_estimate") }}
              </span>
            </template>

            <template #details>
              <span>
                {{ $t("common.details") }}
              </span>
            </template>
            <template
              #optionalServices
              v-if="logistics.selectedLogisticsSchemeId"
            >
              <span>
                {{ $t("warehouse.optional_services") }}
              </span>
            </template>
            <template
              #optionCustom="{ record }"
            >
              <a-button 
              v-if="!logistics.selectedLogisticsSchemeId"
               @click="handleSelectLogistics(record)">{{
                $t("common.select")
              }}</a-button>

            </template>
            <template #logisticsSchemeCustom="{ record }">
              <div class="text-warning">{{ record.scheme }} / {{record.code}}</div>
              <a-row :gutter="[8, 8]">
                <a-col v-for="item in record.descriptionItems" :key="item">
                  <CheckCircleOutlined
                    class="text-warning mr-1"
                    :style="{ fontSize: '12px' }"
                  />
                  <span style="font-size: 12px; color: rgba(102, 102, 102, 1)">
                    {{ item }}
                  </span>
                </a-col>
              </a-row>
            </template>
            <template #logisticsWayCustom="{ record }">
              {{ $t($enumLangkey("logisticsWay", record.logisticsWay)) }}
            </template>
            <template #deliveryWayCustom="{ record }">
              {{ $t($enumLangkey("deliveryWay", record.deliveryWay)) }}
            </template>
            <template #referenceAgingCustom="{ record }">
              <div>
                {{ record.daysMin }}-{{ record.daysMax }}
                {{ $t("warehouse.workday") }}
                ({{ $t($enumLangkey("aging", record.channelTimeType)) }})
              </div>
            </template>
            <template #freightsCustom="{ record }">
              <div class="text-warning">
                {{ $t("warehouse.average_unit_price") }}:
                {{ record.currencySymbol
                }}{{ $filters.amountToFixed2(record.averagePrice) }}
              </div>
              <div class="text-warning">
                {{ $t("warehouse.total_prices") }}: {{ record.currencySymbol
                }}{{ $filters.amountToFixed2(record.expressFee) }}
              </div>
              <div
                style="font-size: 10px; color: #808080"
                v-if="!logistics?.selectedLogisticsSchemeId"
              >
                {{ $t("warehouse.tooltip_text_6") }}
              </div>
            </template>
            <template #detailsCustom="{ record }">
              <div v-if="record.logisticsCalcMethod == 0">
                {{ $t("logistics.chargeable_weight") }}:
                {{ record.chargeableWeight }}kg
              </div>
              <div v-else>
                {{ $t("logistics.chargeable_volume") }}:
                {{ record.chargeableVolume }}m<sup>3</sup>
              </div>
              <div>
                {{ $t("logistics.volume_weight_coefficient")
                }}{{ record.volumeWeightParam }}
              </div>
            </template>
            <template #optionalServicesCustom="{ record }">
              <div v-if="logistics.selectedLogisticsSchemeId">
                <div v-if="record.logisticsRegionInfo.isDdpSupported">
                  <a-checkbox
                    v-model:checked="record.logisticsRegionInfo.isChooseDdp"
                  >
                    DDP(
                    {{ record.currencySymbol }}
                    {{
                      $filters.amountToFixed2(record.logisticsRegionInfo.ddpFee)
                    }}
                    )
                  </a-checkbox>
                </div>
                <div v-if="record.logisticsRegionInfo.isSignSupported">
                  <CSignSupported
                            v-model:value="record.logisticsRegionInfo.signatureType"
                            :symbol="record.currencySymbol"
                            :signFeeOptions="
                              record.logisticsRegionInfo.signFeeDtos
                            "
                            :type="'editable'"
                          />
                </div>
              </div>
              <div v-else>
                  <div v-if="record.logisticsRegionInfo.isDdpSupported">
                    DDP(
                    {{ record.currencySymbol }}
                    {{
                      $filters.amountToFixed2(record.logisticsRegionInfo.ddpFee)
                    }}
                    )
                </div>
                <div v-if="record.logisticsRegionInfo.isSignSupported">
                  <CSignSupported
                            v-model:value="record.logisticsRegionInfo.signatureType"
                            :symbol="record.currencySymbol"
                            :signFeeOptions="
                              record.logisticsRegionInfo.signFeeDtos
                            "
                            :type="'preview'"
                          />
                </div>
              </div>
              
            </template>
            <!-- 卡车专属 -->
            <template #quoteOptionsTitle="{ record }">
              {{ $t("logistics.quote_options") }}
              <CTooltip :title="$t('logistics.quote_options_tips')" />
            </template>
            <template #quoteOptions="{ record }">
              <a-row :gutter="[16, 8]">
                <a-col :span="24">
                  <a-row type="flex">
                    <a-col flex="100px"
                      ><span class="text-error">*</span
                      >{{ $t("logistics.quote_type") }}</a-col
                    >
                    <a-col flex="auto">
                      <a-select
                        v-if="!logistics.selectedLogisticsSchemeId"
                        v-model:value="record.quoteTypeId"
                        size="small"
                        style="width: 150px"
                        :disabled="record.loading"
                        @change="record.quoteDetail = null"
                      >
                        <a-select-option
                          v-for="(item, index) in logistics.quoteTypeList"
                          :key="index"
                          :value="item.id"
                          :title="item.name"
                          >{{ item.name }}</a-select-option
                        >
                      </a-select>
                      <span v-else>{{
                        record.truckBaseInfo.quoteType?.name
                      }}</span>
                    </a-col>
                  </a-row>
                </a-col>
                <a-col :span="24" v-if="funcIsShowServiceLevel(record)">
                  <a-row type="flex">
                    <a-col flex="100px"
                      ><span class="text-error">*</span
                      >{{ $t("logistics.service_level") }}</a-col
                    >
                    <a-col flex="1">
                      <a-select
                        v-if="!logistics.selectedLogisticsSchemeId"
                        v-model:value="record.serviceLevel"
                        size="small"
                        style="width: 150px"
                        :disabled="record.loading"
                        @change="record.quoteDetail = null"
                      >
                        <a-select-option
                          v-for="(item, index) in logistics.serviceLevelList"
                          :key="index"
                          :value="item.id"
                          :title="item.name"
                          >{{ item.name }}</a-select-option
                        >
                      </a-select>
                      <template v-else>
                        <span>{{
                          record.truckBaseInfo.serviceLevel.name
                        }}</span>
                        <span>-</span>
                      </template>
                    </a-col>
                  </a-row>
                </a-col>
                <a-col :span="24">
                  <a-row type="flex">
                    <a-col flex="100px"
                      ><span>&nbsp;&nbsp;</span
                      >{{ $t("logistics.additional_options") }}</a-col
                    >
                    <a-col flex="1">
                      <a-select
                        v-if="!logistics.selectedLogisticsSchemeId"
                        v-model:value="record.accessorials"
                        mode="multiple"
                        size="small"
                        style="width: 150px"
                        :disabled="record.loading"
                        @change="record.quoteDetail = null"
                      >
                        <a-select-option
                          v-for="(
                            item, index
                          ) in logistics.additionalOptionsList"
                          :key="index"
                          :value="item.id"
                          :title="item.name"
                          >{{ item.name }}</a-select-option
                        >
                      </a-select>
                      <span v-else>
                        <a-popover trigger="hover">
                          <template #content>
                            <div style="max-width: 300px">
                              {{
                                getAccessorialsName(
                                  record.truckBaseInfo.accessorials
                                )
                              }}
                            </div>
                          </template>
                          <div
                            style="
                              max-width: 250px;
                              overflow: hidden;
                              white-space: nowrap;
                              text-overflow: ellipsis;
                            "
                          >
                            {{
                              getAccessorialsName(
                                record.truckBaseInfo.accessorials
                              )
                            }}
                          </div>
                        </a-popover>
                      </span>
                    </a-col>
                  </a-row>
                </a-col>
                <a-col :span="24">
                  <a-row type="flex">
                    <a-col flex="100px"></a-col>
                    <a-col flex="1">
                      <a-button
                        v-if="!logistics.selectedLogisticsSchemeId"
                        size="small"
                        type="ghost"
                        :loading="record.loading"
                        @click="handleShowTrackFeeModal(record)"
                        >{{ $t("logistics.inquiry_immediately") }}</a-button
                      >
                    </a-col>
                  </a-row>
                </a-col>
              </a-row>
            </template>
            <template #quoteDetails="{ record }">
              <template v-if="!logistics.selectedLogisticsSchemeId">
                <a-space>
                  <span>{{ $t("logistics.quote") }}:</span>
                  <span v-if="record.quoteDetail">
                    {{ record.quoteDetail.carrierName }}
                    [{{
                      $filters.formatCurrencyAmount(
                        record.quoteDetail.currencySymbol,
                        record.quoteDetail.totalCharge,
                        false
                      )
                    }}
                    , {{ record.quoteDetail.transitTime }} day(s)]</span
                  >
                  <span v-else>-</span>
                </a-space>
                <div class="mt-3" v-if="record.quoteDetail">
                  <a-button
                    type="ghost"
                    :disabled="record.loading"
                    @click="handleConfirmLogistics(record)"
                    >{{ $t("common.confirm") }}</a-button
                  >
                </div>
              </template>
              <template v-else>
                <span v-if="record.truckBaseInfo">
                  {{ record.truckBaseInfo.carrierName }}
                  [{{
                    $filters.formatCurrencyAmount(
                      record.truckBaseInfo.currencySymbol,
                      record.truckBaseInfo.totalCharge,
                      false
                    )
                  }}, {{ record.truckBaseInfo.transitTime }} day(s)]
                </span>
                <span v-else>-</span>
              </template>
            </template>
            
            <template #title>
              <a-button 
                    v-if="logistics.selectedLogisticsSchemeId"
                     @click="hanldeShowLogisticsModal(logistics.currentLogisticInfo)">{{
                    $t("logistics.reselect")
                  }}
              </a-button>
            </template>
            <template #footer="{ record }">
              <a-row type="flex" justify="end" 
                v-if="logistics.selectedLogisticsSchemeId &&
                logistics.calculationMethod == feeCalculationMethodEnum.template
                "
              >
                <a-col>
                   <a-button 
                    type="primary"
                     @click="handleConfirmLogistics('template')">{{
                    $t("common.confirm")
                  }}
                  </a-button>
                </a-col>
              </a-row>
            </template>
          </a-table>

          <!-- <a-row type="flex" justify="center" class="mt-4">
            <a-col>
              <a-button @click="logistics.visible = false">
                {{ $t("common.cancel") }}</a-button
              >
            </a-col>
          </a-row> -->
        </div>
      </a-modal>

      <!-- 卡车费用列表 -->
      <a-modal
        width="800px"
        v-model:visible="trackFeeModal.visible"
        :centered="true"
        :maskClosable="false"
        :ok-button-props="{ hidden: true }"
        :cancelText="$t('common.close')"
        :title="$t('logistics.select_quote')"
      >
        <a-table
          :columns="columnsTrackFee"
          :loading="trackFeeModal.loading"
          :data-source="trackFeeModal.list"
          :scroll="{ x: 500, y: 600 }"
          size="small"
          :rowKey="
            (record, index) => {
              return index;
            }
          "
          :pagination="{
            defaultPageSize: trackFeeModal.pageData.currentPageSize,
            hideOnSinglePage: true,
            current: trackFeeModal.pageData.currentPageIndex,
          }"
          @change="
            (page) => {
              trackFeeModal.pageData.currentPageIndex = page.current;
            }
          "
        >
          <template #carrier>
            {{ $t("logistics.carrier") }}
          </template>
          <template #price>
            {{ $t("logistics.price") }}
          </template>
          <template #aging>
            {{ $t("logistics.aging") }}({{ $t("common.days") }})
          </template>

          <template #priceC="{ record }">
            {{ record.currencySymbol }}
            {{ $filters.amountToFixed2(record?.totalCharge) }}
          </template>

          <template #transitTime="{ record }">
            {{
              isNaN(parseInt(record.transitTime) + "")
                ? record.transitTime
                : parseInt(record.transitTime)
            }}
          </template>

          <template #select="{ record }">
            <div class="ant-form-inline">
              <a-button @click="handleSelectedTrackFee(record)">{{
                $t("common.select")
              }}</a-button>
            </div>
          </template>
        </a-table>
      </a-modal>

      <CAddressModal
        ref="refFromAddress"
        v-model:addressData="addressModal.addressData"
        :isShowSelectCommonAddress="true"
        v-model:visible="addressModal.visible"
        @alterAddressConfirm="handleAlterAddress"
      ></CAddressModal>

      <!-- 更换产品 -->
      <a-modal
        width="850px"
        v-model:visible="productModal.visible"
        :centered="true"
        :maskClosable="false"
        :ok-button-props="{ hidden: true }"
        :cancelText="$t('common.close')"
        :title="$t('common.change_SESKU')"
      >
        <a-form layout="inline">
          <a-form-item class="mb-1">
            <SearchProduct ref="refSearchProduct"></SearchProduct>
          </a-form-item>
          <a-form-item class="mb-1">
            <a-button
              type="primary"
              :loading="productModal.loading"
              @click="hanldeSearchProducts"
              >{{ $t("common.query") }}</a-button
            >
          </a-form-item>
        </a-form>
        <a-table
          :columns="modalColumns"
          :data-source="productModal.list"
          :scroll="{ x: 500, y: 1000 }"
          :pagination="false"
          size="small"
          :loading="productModal.loading"
        >
          <template #productNameAndSeSku>
            <span>
              {{ $t("warehouse.product_name") }}/{{ $t("common.product_no") }}
            </span>
          </template>
          <template #usableInventory>
            <span>
              {{ $t("logistics.usable_inventory") }}
            </span>
          </template>

          <template #productNameAndSeSkuCustom="{ record }">
            <a-space>
              <div class="table-list-img-common">
                <c-image :src="record.picture"></c-image>
              </div>
              <div>
                <div>{{ record.productName }}</div>
                <div>{{ record.productNo }}</div>
              </div>
            </a-space>
          </template>
          <!-- outCount -->

          <template #outCount="{ record }">
            <a-input-number
              v-model:value="record.outCount"
              :disabled="parseInt(record.usableInventory) <= 0"
              :precision="0"
              :min="1"
              :step="1"
              :max="parseInt(record.usableInventory)"
            ></a-input-number>
          </template>

          <template #addButtonCustom="{ record }">
            <div class="ant-form-inline">
              <a-button
                @click="handleAddProductToList(record)"
                :disabled="parseInt(record.usableInventory) <= 0"
                type="primary"
                :loading="productModal.loading"
                ghost
                >{{ $t("common.select") }}</a-button
              >
            </div>
          </template>
        </a-table>
        <a-row type="flex" justify="space-around" align="middle" class="mt-3">
          <a-col>
            <CPager
              @handlePage="handlePage"
              :showLessItems="true"
              :pageSizeOptions="['5', '10', '30']"
              :page-data="productModal.pageData"
            ></CPager>
          </a-col>
        </a-row>
      </a-modal>

      <a-modal
        v-model:visible="data.importConfirmModlaVisible"
        :centered="true"
        :maskClosable="false"
        :footer="null"
      >
        <div class="text-center mt-4">
          <strong>
            {{
              $t("warehouse.the_quantity_of_batch_import_order_is_p0", [
                data?.list?.length,
              ])
            }}
          </strong>
        </div>

        <a-row type="flex" justify="center" class="mt-4">
          <a-col>
            <a-button
              @click="data.importConfirmModlaVisible = false"
              :loading="data.loading"
            >
              {{ $t("common.cancel") }}
            </a-button>
            <a-button
              class="ml-5"
              type="primary"
              :disabled="funcWhetherCanImport()"
              :loading="data.loading"
              @click="hanldeImportConfirm"
            >
              {{ $t("common.confirm_the_import") }}
            </a-button>
          </a-col>
        </a-row>
      </a-modal>

      <a-modal
        v-model:visible="errorMsgModal.visible"
        :centered="true"
        :maskClosable="false"
        :footer="null"
      >
        <div class="text-center mt-4" >
          <strong>
             <span > {{
              $t("common.the_number_of_successes_is_p0", [
                 " "+errorMsgModal.succeedCount,
              ])
            }}
            </span> 
            <span v-if="errorMsgModal.failCount>0"> , </span>
             <span class="text-error" v-if="errorMsgModal.failCount>0"> 
              <!-- 失败数量为 {{errorMsgModal.failCount}} -->
              {{
              $t("common.the_number_of_fail_is_p0", [
                 " "+errorMsgModal.failCount,
              ])
            }}
            </span>
          </strong>
        </div>
        <div v-if="errorMsgModal.failCount>0" >
         <span> {{$t('common.fail_order')}} </span>:
          <div  class="p-2 messageCard" v-if="errorMsgModal?.errObjs?.length>0">
            <div class="mt-2" 
               v-for="(item,index) in errorMsgModal?.errObjs" 
              :key="index">
              <span 
              > 
                {{index+1}}. [{{item.relationOrderNo}}]:  {{$t(`error.${item?.errCode}`)}}
              </span>
            </div>
          </div>
        </div>

        <a-row type="flex" justify="center" class="mt-4">
          <a-col>
            <a-button
              type="primary"
              @click="errorMsgModal.visible=!errorMsgModal.visible"
            >
              {{ $t("common.close") }}
            </a-button>
          </a-col>
        </a-row>
      </a-modal>

    </template>
  </Content>
</template>

<script>
import { useI18n } from "vue-i18n/index";
import { reactive, ref } from "vue";
import {
  Row,
  Col,
  Popconfirm,
  Menu,
  Upload,
  Card,
  Modal,
  Tabs,
  Table,
  Input,
  Dropdown,
  Tag,
  Popover,
  Descriptions,
  Checkbox,
  Select,
  Button,
  Form,
  message,
  DatePicker,
  Space,
  InputNumber,
} from "ant-design-vue";
import Content from "../../components/Content.vue";
import {
  parseFile,
  importConfirm,
  joinDownloadTemplateUrl,
  validationTableData,
} from "../../../api/modules/consignment/orderBulkImport";
import {
  agentOrderLogisticsType as logisticsTypeEnum,
  feeCalculationMethod as feeCalculationMethodEnum,
  deliveryWay as deliveryWayEnum,
  packMaterialType as packMaterialTypeEnum,
  agentOrderLogisticsType as agentOrderLogisticsTypeEnum,
} from "../../../enum/enum.json";
import {} from "../../../api/modules/common/index";
import { getName, gToKg, cmCubicToM } from "../../../utils/general";
import { useStore } from "vuex";
import { getAddressByLanguageV2 } from "../../../utils/general";
import CFileDisplay from "../../components/CFileDisplay.vue";
import CImage from "../../components/CImage.vue";
import CustomsUpload from "../../transport/components/Upload.vue";
import { getErrorMsgKey } from "../../../api/errorHandlers";
import QuotedPriceModal from "../../transport/components/QuotedPriceModal.vue";
import { useSupplierHelper } from "@/utils/supplierHelper.js";
import CTooltip from "@/views/components/CTooltip.vue";
import {
  getOrderFreightEstimate,
  getTrackLogisticsFee,
} from "../../../api/modules/consignment/index";
import { downloadFile } from "../../../utils/downloader";
import CAddressModal from "../components/CAddressModal.vue";
import SearchProduct from "../../components/SearchProduct.vue";
import { getInventoryProductList ,getLogisticsFee} from "../../../api/modules/consignment/index";
import CPager from "../../components/CPager.vue";
import { getPackagingType } from "../../../api/modules/consignment/index";
import CSignSupported from "@/views/components/CSignSupported.vue";
import { maxUploadFileSizeByte } from '../../../utils/common';

export default {
  name: "consignment_order_bulk_import",
  components: {
    CSignSupported,
    CTooltip,
    CAddressModal,
    SearchProduct,
    QuotedPriceModal,
    CPager,
    CustomsUpload,
    CFileDisplay,
    Content,
    ARow: Row,
    ACol: Col,
    ATable: Table,
    AInput: Input,
    AInputNumber: InputNumber,
    APopconfirm: Popconfirm,
    AInputSearch: Input.Search,
    ASelect: Select,
    AButton: Button,
    ARangePicker: DatePicker.RangePicker,
    ASelectOption: Select.Option,
    AFormItem: Form.Item,
    AForm: Form,
    ADropdown: Dropdown,
    ADropdownButton: Dropdown.Button,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    ATag: Tag,
    ASpace: Space,
    AUpload: Upload,
    ACard: Card,
    CImage,
    APopover: Popover,
    ADescriptions: Descriptions,
    ADescriptionsItem: Descriptions.Item,
    AModal: Modal,
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    ACheckbox: Checkbox,
  },
  setup() {
    const { getters } = useStore();
    const i18n = useI18n({ useScope: "global" });

    const getAddress = (address) => {
      return getAddressByLanguageV2(address, getters.language);
    };

    const columnsImport = [
      {
        title: () => i18n.t("warehouse.serial_number"),
        customRender: ({ text, record, index }) => {
          return index + 1;
        },
      },
      {
        title: () => i18n.t("logistics.relation_order_no"),
        slots: {
          customRender: "relationOrderNo",
        },
      },
      {
        title: () => i18n.t("logistics.distribution_channels"),
        slots: {
          customRender: "saleSourceName",
        },
      },
      {
        title: () => i18n.t("logistics.consignment_warehouse_no"),
        slots: {
          customRender: "warehouseNo",
        },
      },
      {
        title: () =>
          i18n.t("logistics.recipients") + "/" + i18n.t("warehouse.phone"),
        slots: {
          customRender: "recipientsAndPhone",
        },
      },
      {
        title: () => i18n.t("logistics.destination_address"),
        slots: {
          customRender: "destination_address",
        },
      },
      {
        title: () => i18n.t("logistics.transport_scheme"),
        slots: {
          customRender: "transport_scheme",
        },
      },
      {
        title: () => i18n.t("warehouse.packing_material_selection"),
        slots: {
          customRender: "pack_material",
        },
      },
      {
        title: () => i18n.t("common.operation"),
        slots: {
          customRender: "delete",
        },
      },
    ];

    const innerColumnsImport = [
      {
        title: () => i18n.t("logistics.product"),
        slots: {
          customRender: "product",
        },
        width: 100,
      },
      {
        title: "SESKU",
        slots: {
          customRender: "seSku",
        },
        // dataIndex: "seSku",
        width: 100,
      },
      {
        title: () => i18n.t("logistics.number") + "(Unit)",
        // dataIndex: "outCount",
        slots: {
          customRender: "outCount",
        },
        width: 100,
      },
      {
        title:() => i18n.t("common.hint"),
        slots: {
          customRender: "errorMsg",
        },
        width: 100,
      },
      {
        title: () => i18n.t("common.operation"),
        slots: {
          customRender: "deleteProduct",
        },
        width: 100,
      },
    ];

    //获得材料类型的中英文text
    const getText = (item) => {
      if (!item) {
        return;
      }
      return getName(
        { cnName: item.materialTypeCnName, enName: item.materialTypeEnName },
        getters.language
      );
    };

    const data = reactive({
      downloadLoading: false,
      list: [],
      listVisible: false,
      loading: false,
      importConfirmModlaVisible: false,
      packagingTypeListCache: [], //材料下拉数据缓存
      packagingTypeListCacheKey: [], //材料下拉数据key缓存
    });

    //包装材料的下拉框
    const getPackingMaterials = (warehouseId) => {
      if (!warehouseId) {
        return;
      }

      if (data.packagingTypeListCache[warehouseId]) {
        return data.packagingTypeListCache[warehouseId];
      }

      if (data.packagingTypeListCacheKey[warehouseId]) {
        return;
      } else {
        data.packagingTypeListCacheKey[warehouseId] = true;
      }

      let pra = {
        warehouseId: warehouseId,
      };
      getPackagingType(pra).then((res) => {
        if (res.result) {
          data.packagingTypeListCache[warehouseId] = res.result.map(
            (x, index) => {
              return {
                key: index,
                id: x.id,
                name: x.packMaterialName,
                materialTypeCnName: x.materialTypeCnName,
                materialTypeEnName: x.materialTypeEnName,
                length: x.packMaterialLength,
                width: x.packMaterialWidth,
                height: x.packMaterialHeight,
                weight: gToKg(x.packMaterialWeight), //材料重量
                packBearingQuantity: gToKg(x.packBearingQuantity), //最大容重
                maxVolume: cmCubicToM(x.packMaterialVolume), //材料最大承重
                maxBearingQuantity: gToKg(x.packBearingQuantity),
                fee: x.price,
                feeUnit: x.currencySymbol,
                feeCode: x.feeCode,
              };
            }
          );
        }
      });
    };

    //判断改变数据是否影响卡车
    const _isChangeTruckQuote = (feeCalculationMethod, currencyOrder) => {
      if (feeCalculationMethod == feeCalculationMethodEnum.truckApi) {
        message.warning(
          i18n.t(
            "logistics.changing_the_data_will_affect_the_truck_quotation_of_logistics_please_re_select_logistics"
          )
        );
        //让当前显示清空
        currencyOrder.logistic.logisticsBaseInfo.name = null;
        //避免更换其他order,验证后导致这条错误方案又被后端传回来
        currencyOrder.logistic.logisticsBaseInfo.code = null;
        currencyOrder.logistic.logisticsBaseInfo.deliveryType = null;
        currencyOrder.logistic.logisticsBaseInfo.truckBaseInfo = {
          //避免在后续因为给对象的字段赋值为null而出异常
          accessorials: null,
          carrierName: null,
          currencySymbol: null,
          quoteDetailId: null,
          quoteType: { id: null, name: null },
          serviceLevel: null,
          totalCharge: null,
          transitTime: null,
        };
        currencyOrder.whetherCanImport = false;
      } else {
        if (
          !currencyOrder.logistic.logisticsBaseInfo.code &&
          currencyOrder.agentOrderLogisticsType == logisticsTypeEnum.seLogistics
        ) {
          return;
        }
        funcValidationTableData();
      }
    };

    const hanldeChangeMaterialType = (record) => {
      funcValidationTableData();
      // if (record.packMaterialType == packMaterialTypeEnum.mergePackaging) {
      // }
    };

    //改变包装材料
    const handelChangeSelectPackagingType = (record) => {
      let packingMaterials = data.packagingTypeListCache[record.warehouseId];
      record.packMaterialConfig.feeCode =
        packingMaterials?.find((x) => x.id == record.packMaterialConfig?.id)
          ?.feeCode ?? null;

      _isChangeTruckQuote(
        record.logistic?.logisticsBaseInfo?.feeCalculationMethod,
        record
      );
    };

    //下载模板文件
    const hanldeDownloadTemplateFile = () => {
      data.downloadLoading = true;
      let url = joinDownloadTemplateUrl();
      downloadFile(url, "Shipping order template file.xlsx");
      data.downloadLoading = false;
    };

    //上传文件并解析
    const handleParseFile = (file) => {
      if (file?.file?.size > maxUploadFileSizeByte() ) {
        message.error(i18n.t('common.please_upload_file_with_size_less_than_50MB'));
        return
      }

        message.loading({
        content: '解析文件中...',
        key:"Parse",
        duration: 2,
      });
      data.loading = true;
      let parameter = new FormData();
      parameter.append("file", file.file);
      parseFile(parameter)
        .then(({ result }) => {
          _mapList(result);
          data.loading = false;
        })
        .catch((res) => {
          data.listVisible = false;
          data.loading = false;
        });
    };

    const _mapList = (result) => {
      if (result) {
        let list = result;
        list.forEach((x) => {
          if (x.otherSurface) {
            x.otherSurface.surfaceUrl =
              x.otherSurface?.surfaceUrl?.length > 0
                ? x.otherSurface?.surfaceUrl[0]
                : null;
          } else {
            //避免在后续因为otherSurface对象为null,赋值引起的错误
            x.otherSurface = {
              deliveryType: null,
              surfaceUrl: null,
            };
          }

          //处理后端返回id为0导致下拉框显示为0
          if (x.packMaterialConfig.id == "0") {
            x.packMaterialConfig.id = null;
          }

           if (x.receiveAddressData && x.receiveAddressData.phoneCountryId == "0") {
              x.receiveAddressData.phoneCountryId = null;
           }

          let itemWhetherCanImport = !(
            !x.isNormal || x.products.findIndex((p) => !p.isNormal) >= 0
          );
          //一条数据是能导入 (无异常(包含产品))
          x.whetherCanImport = itemWhetherCanImport;

          x.isShowProductError= x.products?.findIndex(x=>!x?.isNormal) >= 0;
        });
        data.list=list;
      } else {
        data.list = null;
      }
      data.listVisible = true;
    };

    //判断整个table是否能导入 (页面无错误数据（包括产品）为true,可导入)
    const funcWhetherCanImport = () => {
      return (
        data.list.length == 0 ||
        data.list.findIndex((p) => !p.whetherCanImport) >= 0
      );
    };

    //导入前验证vat, 图片，派送方式和 数据是否异常
    const _verificationData = () => {
      let passed = true;
      data.list.some((x) => {
        if (
          x.agentOrderLogisticsType == logisticsTypeEnum.seLogistics &&
          x?.logistic?.logisticsRegionInfo?.isVatRequired &&
          (!x?.logistic?.logisticsRegionInfo?.vatCode ||
            x?.logistic?.logisticsRegionInfo?.vatCode?.trim()?.length == 0)
        ) {
          message.error(i18n.t("common.p0_is_required", ["VAT"]));
          passed = false;
          return true;
        }

        if (
          x.agentOrderLogisticsType == logisticsTypeEnum.thirdparty &&
          !x?.otherSurface?.deliveryType
        ) {
          passed = false;
          message.error(
            i18n.t("common.p0_is_required", [i18n.t("warehouse.delivery_way")])
          );
          return true;
        }

        if (
          x.agentOrderLogisticsType == logisticsTypeEnum.thirdparty &&
          !x?.otherSurface?.surfaceUrl
        ) {
          passed = false;
          message.error(
            i18n.t("common.p0_is_required", [i18n.t("common.upload_file")])
          );
          return true;
        }
      });
      return passed;
    };

    //导入按钮=>显示导入数量modal
    const hanldeShowImporMessage = () => {
      if (!_verificationData()) {
        return;
      }
      data.importConfirmModlaVisible = true;
    };

    // 确认导入
    const hanldeImportConfirm = () => {
      data.loading = true;
      let parameter = [];
      data.list.forEach((x) => {
        if (x.isNormal) {
          let products = _getProductDatas(x.products);
          let otherSurface = null;

          let tempData = null;

          //三方面单
          if (x.agentOrderLogisticsType == logisticsTypeEnum.thirdparty) {
            otherSurface = {
              deliveryType: x.otherSurface.deliveryType,
              surfaceUrl: [x.otherSurface.surfaceUrl],
            };

            tempData = {
              relationOrderNo: x.relationOrderNo,
              warehouseId: x.warehouseId,
              saleSourceName: x.saleSourceName,
              packMaterialType: x.packMaterialType,
              packMaterialId: x.packMaterialConfig?.id,
              productDatas: products,
              otherSurface: otherSurface,
              logisticsType: x.agentOrderLogisticsType,
            };
            //物流-卡车
          } else if (
            x.logistic?.logisticsBaseInfo?.feeCalculationMethod ==
            feeCalculationMethodEnum.truckApi
          ) {
            let accessorials =
              x.logistic?.logisticsBaseInfo?.truckBaseInfo?.accessorials?.map(
                (x) => x.id
              );

            tempData = {
              relationOrderNo: x.relationOrderNo,
              warehouseId: x.warehouseId,
              saleSourceName: x.saleSourceName,
              packMaterialType: x.packMaterialType,
              packMaterialId: x.packMaterialConfig?.id,
              logisticsId: x.logistic?.logisticsBaseInfo?.id,
              productDatas: products,
              receiveAddressData: x.receiveAddressData,
              logisticsType: x.agentOrderLogisticsType,
              truckApiLogistics: {
                quoteDetailId:
                  x.logistic?.logisticsBaseInfo?.truckBaseInfo.quoteDetailId,
                quoteTypeId:
                  x.logistic?.logisticsBaseInfo?.truckBaseInfo.quoteType.id,
                serviceLevel:
                  x.logistic?.logisticsBaseInfo?.truckBaseInfo.serviceLevel,
                accessorials: accessorials,
              },
            };
            //物流-模板计算
          } else {
            tempData = {
              relationOrderNo: x.relationOrderNo,
              warehouseId: x.warehouseId,
              saleSourceName: x.saleSourceName,
              packMaterialType: x.packMaterialType,
              packMaterialId: x.packMaterialConfig?.id,
              logisticsId: x.logistic?.logisticsBaseInfo?.id,
              productDatas: products,

              receiveAddressData: x.receiveAddressData,

              isChooseDdp: x.logistic?.logisticsRegionInfo?.isChooseDdp,
              isChooseSign: x.logistic?.logisticsRegionInfo?.signatureType > 0,
              signatureType: x.logistic?.logisticsRegionInfo?.signatureType,
              vatCode: x.logistic?.logisticsRegionInfo?.vatCode,
              logisticsType: x.agentOrderLogisticsType,
              truckApiLogistics: null,
            };
          }

          parameter.push(tempData);
        }
      });

      //确认导入
      importConfirm(parameter)
        .then(({ result }) => {
          if (!result.failCount ||result.failCount==0) {
            //失败数量为0,就隐藏解析文件table
            data.listVisible = false;
          }
          errorMsgModal.visible=true;
          errorMsgModal.succeedCount=result?.succeedCount??0;
          errorMsgModal.failCount=result?.failCount??0;
          errorMsgModal.errObjs=result.errObjs;

          data.loading = false;
          data.importConfirmModlaVisible = false;
        })
        .catch(() => {
          data.loading = false;
          data.importConfirmModlaVisible = false;
        });
    };

    const hanldeDeleteData = (index) => {
      data.list = data.list.filter((x, i) => i != index);
      funcValidationTableData();
    };

    const hanldeDeleteProduct = (record, index) => {
      let targetOrder = data.list.find(
        (x) => x.relationOrderNo == record.relationOrderNo
      );
      if (targetOrder?.products?.length <= 1) {
        message.error(i18n.t("warehouse.you_need_at_least_one_product"));
        return;
      } else {
        let products = targetOrder?.products?.filter((x, i) => i != index);
        targetOrder.products = products;
        funcValidationTableData();
      }
    };

    //-----------------重新地址 start------------------------
    const addressModal = reactive({
      //-- 不要记得删
      addressData: {
        selectedCommonAddress: null,
        // commonAddressName: "",//发自\发至
        selectedCountrys: null,
        selectedProvinces: null,
        selectedCity: null,
        selectedCounty: null,
        detailedAddress: null,
        detailedAddress2: null,
        postCode: "",
        contactPhone: null,
        phoneCountryId: null,
        receiptUserName: null,
      },
      currentLogisticInfo: null,
      visible: false,
    });

    const hanldeShowAddressModal = (record) => {
      addressModal.addressData.selectedCommonAddress =
        record.receiveAddressData.id;

      addressModal.addressData.selectedCountrys =
        record.receiveAddressData.countryId;
      addressModal.addressData.selectedProvinces =
        record.receiveAddressData.provinceId;
      addressModal.addressData.selectedCity = record.receiveAddressData.cityId;
      addressModal.addressData.selectedCounty =
        record.receiveAddressData.countyId;

      addressModal.addressData.detailedAddress =
        record.receiveAddressData.address;
      addressModal.addressData.detailedAddress2 =
        record.receiveAddressData.address2;
      addressModal.addressData.postCode = record.receiveAddressData.postCode;

      addressModal.addressData.contactPhone =
        record.receiveAddressData.contactPhone;
      addressModal.addressData.phoneCountryId = 
      record.receiveAddressData.phoneCountryId;
      addressModal.addressData.receiptUserName =
        record.receiveAddressData.receiptUserName;

      addressModal.visible = true;
      addressModal.currentLogisticInfo = record;
    };

    const handleAlterAddress = (data) => {
      addressModal.currentLogisticInfo.receiveAddressData = data;

      _isChangeTruckQuote(
        addressModal.currentLogisticInfo.logistic?.logisticsBaseInfo
          ?.feeCalculationMethod,
        addressModal.currentLogisticInfo
      );
    };

    //修改地址或更改sku,或删除订单,删除产品后验证 table中数据
    const funcValidationTableData = () => {
      if (!data.list || data.list?.length == 0) {
        return;
      }
      let pra = [];
      data.list?.forEach((x) => {
        if (x.agentOrderLogisticsType == logisticsTypeEnum.seLogistics) {
          if (x?.products?.length > 0) {
            x?.products.forEach((p) => {
              let tempData = null;
              if (
                x.logistic?.logisticsBaseInfo?.feeCalculationMethod ==
                feeCalculationMethodEnum.truckApi
              ) {
                let truckApiLogistics = {
                  quoteDetailId:
                    x.logistic?.logisticsBaseInfo?.truckBaseInfo.quoteDetailId,
                  quoteTypeId:
                    x.logistic?.logisticsBaseInfo?.truckBaseInfo.quoteType?.id,
                  serviceLevel:
                    x.logistic?.logisticsBaseInfo?.truckBaseInfo.serviceLevel,
                  accessorials:
                    x.logistic?.logisticsBaseInfo?.truckBaseInfo.accessorials,
                };

                tempData = {
                  relationOrderNo: x.relationOrderNo,
                  warehouseNo: x.warehouseNo,
                  saleSourceName: x.saleSourceName,
                  productNo: p.no,
                  count: p.outCount, //循环
                  packMaterialType: x.packMaterialType,
                  packMaterialCode: x.packMaterialConfig?.feeCode,
                  agentOrderLogisticsType: x.agentOrderLogisticsType,

                  logisticsCode: x.logistic?.logisticsBaseInfo?.code,
                  truckApiLogistics: truckApiLogistics, //卡车

                  receiptUserName: x.receiveAddressData?.receiptUserName,
                  contactPhone: x.receiveAddressData?.contactPhone,
                  phoneCountryId:x.receiveAddressData?.phoneCountryId,
                  areaCode:x.receiveAddressData?.areaCode,
                  ioS2: x.receiveAddressData?.ioS2,
                  abbrCode: x.receiveAddressData?.abbrCode,
                  cityEnName: x.receiveAddressData?.cityEnName,
                  address: x.receiveAddressData?.address,
                  address2: x.receiveAddressData?.address2,
                  postCode: x.receiveAddressData?.postCode,
                };
              } else {
                tempData = {
                  relationOrderNo: x.relationOrderNo,
                  warehouseNo: x.warehouseNo,
                  saleSourceName: x.saleSourceName,
                  productNo: p.no,
                  count: p.outCount, //循环
                  packMaterialType: x.packMaterialType,
                  packMaterialCode: x.packMaterialConfig?.feeCode,
                  agentOrderLogisticsType: x.agentOrderLogisticsType,

                  logisticsCode: x.logistic?.logisticsBaseInfo?.code,
                  isChooseDdp: x.logistic?.logisticsRegionInfo?.isChooseDdp,
                  isChooseSign: x.logistic?.logisticsRegionInfo?.signatureType > 0,
                  signatureType: x.logistic?.logisticsRegionInfo?.signatureType,
                  vatCode: x.logistic?.logisticsRegionInfo?.vatCode,

                  receiptUserName: x.receiveAddressData?.receiptUserName,
                  contactPhone: x.receiveAddressData?.contactPhone,
                  phoneCountryId:x.receiveAddressData?.phoneCountryId,
                  areaCode:x.receiveAddressData?.areaCode,
                  ioS2: x.receiveAddressData?.ioS2,
                  abbrCode: x.receiveAddressData?.abbrCode,
                  cityEnName: x.receiveAddressData?.cityEnName,
                  address: x.receiveAddressData?.address,
                  address2: x.receiveAddressData?.address2,
                  postCode: x.receiveAddressData?.postCode,
                };
              }
              pra.push(tempData);
            });
          }
        } else {
          //第三方面单
          if (x?.products?.length > 0) {
            x?.products.forEach((p) => {
              let tempData = {
                relationOrderNo: x.relationOrderNo,
                warehouseNo: x.warehouseNo,
                saleSourceName: x.saleSourceName,
                productNo: p.no,
                count: p.outCount, //循环
                packMaterialType: x.packMaterialType,
                packMaterialCode: x?.packMaterialConfig?.feeCode,
                agentOrderLogisticsType: x?.agentOrderLogisticsType,

                otherSurfaceDeliveryType: x?.otherSurface?.deliveryType,
                otherSurfaceImg: x?.otherSurface?.surfaceUrl,

                // 第三方面单不需要地址和联系人
              };
              pra.push(tempData);
            });
          }
        }
      });

      data.loading = true;
      validationTableData(pra)
        .then(({ result }) => {
          // 重组页面数据
          _mapList(result);
          data.loading = false;
        })
        .catch(() => {
          data.loading = false;
        });
    };

    //-----------------重新地址 end------------------------------------------------------------------------

    //-----------------更换sesku start------------------------------------------------------------------------

    const modalColumns = [
      {
        slots: {
          title: "productNameAndSeSku",
          customRender: "productNameAndSeSkuCustom",
        },
        width: 150,
      },
      {
        title: 'SESKU',
        dataIndex: "seSku",
        width: 100,
      },
      {
        dataIndex: "usableInventory",
        slots: {
          title: "usableInventory",
        },
        width: 50,
      },
      {
        title: () => i18n.t("logistics.number") + "(Unit)",
        slots: {
          customRender: "outCount",
        },
        width: 100,
      },
      {
        title: "",
        dataIndex: "addButton",
        slots: {
          customRender: "addButtonCustom",
        },
        width: 100,
      },
    ];

    const refSearchProduct = ref();

    const productModal = reactive({
      visible: false,
      loading: false,
      list: [],
      pageData: {
        currentIndex: 1,
        skipCount: 0,
        totalCount: 0,
        maxResultCount: 5,
      },
      currentProduct: null,
    });

    const handleShowModalProduct = async (record) => {
      let orderItem = data.list.find(
        (x, i) => x.relationOrderNo == record.relationOrderNo
      );
      if (!orderItem?.warehouseId) {
        message.error(
          i18n.t("warehouse.p0_is_abnormal_and_cannot_be_modified", [
            i18n.t("warehouse.consignment_code"),
          ])
        );
        return;
      } else {
        productModal.warehouseId = orderItem.warehouseId;
      }

      productModal.currentProduct = record;
      productModal.visible = true;
      productModal.list = [];
      hanldeSearchProducts();
    };

    const handlePage = async (pageData) => {
      productModal.pageData.skipCount = pageData.skipCount;
      productModal.pageData.maxResultCount = pageData.maxResultCount;
      handleGetProducts();
    };

    const hanldeSearchProducts = () => {
      productModal.pageData.skipCount = 0;
      productModal.pageData.currentIndex = 1;
      handleGetProducts();
    };

    const handleGetProducts = async () => {
      productModal.loading = true;
      const searchData = {
        warehouseId: productModal.warehouseId,
        productId: refSearchProduct.value
          ? refSearchProduct.value.selectedProductId
          : "",
        ...productModal.pageData,
      };
      getInventoryProductList(searchData)
        .then((res) => {
          if (res.result) {
            let { items, totalCount } = res.result;
            let tempList = [];
            items.forEach((x, index) => {
              //显示库存减去页面 已添加的产品
              let usedInventory = null;
              data.list.forEach((d) => {
                d?.products?.forEach((p) => {
                  if (
                    p.seSku == x.seSku &&
                    !Object.is(p, productModal.currentProduct)
                  ) {
                    usedInventory = usedInventory + p.outCount;
                  }
                });
              });

              tempList.push({
                key: index,
                productId: x.productId,
                picture: x.imgUrl,
                productName: x.productName,
                productNo: x.productNo,
                usableInventory:
                  x.count - usedInventory < 0 ? 0 : x.count - usedInventory,
                length: x.packingLength,
                width: x.packingWidth,
                seSku: x.seSku,
                height: x.packingHeight,
                weight: gToKg(x.packingWeight) + "kg",
                transportCharacteristics: x.transportCharacteristics,
              });
            });
            productModal.list = tempList;
            productModal.pageData.totalCount = parseInt(totalCount);
          } else {
            productModal.pageData.totalCount = 0;
            productModal.list = [];
          }
          productModal.loading = false;
        })
        .catch((r) => {
          productModal.loading = false;
        });
    };

    const handleAddProductToList = async (record) => {
      if (!record.outCount) {
        message.error(
          i18n.t("common.p0_is_required", [
            i18n.t("warehouse.product_quantity"),
          ])
        );
        return;
      }

      productModal.currentProduct.name = record?.productName;
      productModal.currentProduct.no = record?.productNo;
      productModal.currentProduct.seSku = record?.seSku;
      productModal.currentProduct.imgUrl = record?.picture;
      productModal.currentProduct.outCount = record?.outCount;
      productModal.visible = false;
      let orderItem = data.list.find(
        (x, i) =>
          x.relationOrderNo == productModal.currentProduct.relationOrderNo
      );
      _isChangeTruckQuote(
        orderItem.logistic?.logisticsBaseInfo?.feeCalculationMethod,
        orderItem
      );
    };

    //-----------------更换sesku end------------------------------------------------------------------------

    //-----------------重新选择物流 start------------------------------------------------------------------------
    // 物流 模板计算columns
    const columnsLogisticsTemplate = [
      {
        dataIndex: "option",
        slots: {
          title: "option",
          customRender: "optionCustom",
        },
        width: 100,
      },
      {
        dataIndex: "logisticsScheme",
        slots: {
          title: "logisticsScheme",
          customRender: "logisticsSchemeCustom",
        },
        width: 200,
      },
      {
        dataIndex: "logisticsWay", //物流类型
        slots: {
          title: "logisticsWay",
          customRender: "logisticsWayCustom", //物流名称和基础服务
        },
        width: 100,
      },
      {
        dataIndex: "deliveryWay", //交付方式
        slots: {
          title: "deliveryWay",
          customRender: "deliveryWayCustom",
        },
        width: 100,
      },
      {
        dataIndex: "referenceAging", //参考时效
        slots: {
          title: "referenceAging",
          customRender: "referenceAgingCustom",
        },
        width: 150,
      },
      {
        dataIndex: "freights", //运费
        width: 200,
        slots: {
          title: "freights",
          customRender: "freightsCustom", //平均单价和合计
        },
      },
      {
        dataIndex: "details", //详情
        slots: {
          title: "details",
          customRender: "detailsCustom", //计费重、体积重系数：除600
        },
        width: 150,
      },
      {
        dataIndex: "optionalServices", //可选服务
        slots: {
          title: "optionalServices",
          customRender: "optionalServicesCustom",
        },
        width: 230,
      },
    ];

    //  物流 卡车计算columns
    const columnsLogisticsTruck = [
      {
        width: 200,
        slots: {
          title: "logisticsScheme",
          customRender: "logisticsSchemeCustom",
        },
      },
      {
        width: 100,
        slots: {
          title: "logisticsWay",
          customRender: "logisticsWayCustom", //物流名称和基础服务
        },
      },
      {
        width: 100,
        slots: {
          title: "deliveryWay",
          customRender: "deliveryWayCustom",
        },
      },
      {
        width: 200,
        slots: {
          title: "quoteOptionsTitle",
          customRender: "quoteOptions",
        },
      },
      {
        width: 200,
        title: () => i18n.t("logistics.quote_details"),
        slots: {
          customRender: "quoteDetails",
        },
      },
    ];

    const supplierHelper = useSupplierHelper();

    const logistics = reactive({
      loading: false,
      visible: false,

      calculationMethod: null, //模板计算或卡车
      selectedLogisticsSchemeId: null,

      logisticsSchemeList: [], //查的卡车和物流方案总数据
      logisticsSchemeExhibitionColumns: [], //最后显示的columns
      logisticsSchemeExhibitionList: [], //最后显示的数据tables数据

      isShowLogisticsTabs: false, //是否显示tab切换 卡车或物流
      // 卡车-报价类型
      quoteTypeList: [],
      // 卡车-服务等级
      serviceLevelList: [],
      // 卡车-附加选项
      additionalOptionsList: [],

      currentLogisticInfo: null, //当前正在修改的订单的物流方案信息
    });

    //是否显示服务等级
    const funcIsShowServiceLevel = (record) => {
      let item = logistics.quoteTypeList.find(
        (item) => item.id === record.quoteTypeId
      );
      if (item) {
        return item.needService;
      }
      return false;
    };

    const getAccessorialsName = (list) => {
      if (!Array.isArray(list)) {
        return "-";
      }
      return list.map((item) => item.name).join(", ");
    };

    //获取附加选项的下拉框数据
    const getSupplierList = async () => {
      try {
        logistics.quoteTypeList = await supplierHelper.getQuoteTypeList();
        logistics.serviceLevelList = await supplierHelper.getServiceLevelList();
        logistics.additionalOptionsList =
          await supplierHelper.getAdditionalOptionsList();
      } catch (error) {}
    };

    //显示物流modal
    const hanldeShowLogisticsModal = (record) => {
      //----修改物流之前验证 start--------------------
      if (!record.warehouseId) {
        message.error(
          i18n.t("warehouse.p0_is_abnormal_and_cannot_be_modified", [
            i18n.t("warehouse.consignment_code"),
          ])
        );
        return;
      }
      quotesCacheObj = {}; //清除卡车缓存
      let products = [];
      let isProductError = null;
      let productCount = 0;
      try {
        record.products.forEach((x) => {
          if (!x.isNormal) {
            isProductError = true;
            throw Error();
          }
          productCount = productCount + x.outCount;
          products.push({
            productId: x.productId,
            count: x.outCount,//获取物流方案用
            outCount: x.outCount,//获取某个物流方案详情用
          });
        });
      } catch (error) {
        //nothing
      }

      if (isProductError) {
        message.error(
          i18n.t("warehouse.p0_is_abnormal_and_cannot_be_modified", [
            i18n.t("warehouse.product"),
          ])
        );
        return;
      }

      if (
        productCount > 1 &&
        !record.packMaterialConfig?.id &&
        record.packMaterialType != packMaterialTypeEnum.mergePackaging
      ) {
        message.error(
          i18n.t("warehouse.p0_is_abnormal_and_cannot_be_modified", [
            i18n.t("logistics.pack_material"),
          ])
        );
        return;
      }

      if (
        !record.receiveAddressData ||
        !record.receiveAddressData?.countryId ||
        !record.receiveAddressData?.provinceId
      ) {
        message.error(
          i18n.t("warehouse.p0_is_abnormal_and_cannot_be_modified", [
            i18n.t("logistics.destination_address"),
          ])
        );
        return;
      }
      //----修改物流之前验证 end--------------------

      logistics.visible = true;
      logistics.loading = true;
      logistics.currentLogisticInfo = record;

      logistics.calculationMethod = feeCalculationMethodEnum.template;
      logistics.selectedLogisticsSchemeId = "";
      logistics.logisticsSchemeList = [];

      let pra = {
        warehouseId: record.warehouseId,
        packMaterialId: record.packMaterialConfig?.id ?? null,
        toAddr: record.receiveAddressData,
        products: products,
        packMaterialType:record.packMaterialType
      };
      logistics.currentLogisticInfo.productsParameter = products;

      getOrderFreightEstimate(pra)
        .then((res) => {
          if (res.result && res.result.length > 0) {
            logistics.logisticsSchemeList = res.result.map((x, index) => {
              return {
                key: index,
                id: x.logisticsBaseInfo.id,
                scheme: x.logisticsBaseInfo.name,
                code: x.logisticsBaseInfo.code,
                descriptionItems: x.logisticsBaseInfo.descriptionItems,

                logisticsWay: x.logisticsBaseInfo.logisticsType,
                deliveryWay: x.logisticsBaseInfo.deliveryType,

                daysMin: x.logisticsBaseInfo.daysMin,
                daysMax: x.logisticsBaseInfo.daysMax,
                channelTimeType: x.logisticsBaseInfo.channelTimeType, //签收或自提 枚举

                averagePrice: x.logisticsBaseInfo.averagePrice, //平均费
                expressFee: x.logisticsBaseInfo.totalFeeWithoutServices, //合计费用
                currencySymbol: x.logisticsBaseInfo.currencySymbol, //货号符号

                chargeableWeight: x?.logisticsBaseInfo?.chargeableWeight
                  ? gToKg(x.logisticsBaseInfo.chargeableWeight)
                  : "0", //计费重
                chargeableVolume: cmCubicToM(
                  x.logisticsBaseInfo.chargeableVolume
                ),
                logisticsCalcMethod: x.logisticsBaseInfo.logisticsCalcMethod,

                volumeWeightParam: x.logisticsBaseInfo.volumeWeightParam, //体积系数
                declareSupported: x.logisticsBaseInfo.declareSupported, //是否支持一般贸易报关
                logisticsRegionInfo: x.logisticsRegionInfo, //是否支持DDP和签字

                // 卡车相关
                calculationMethod: x.logisticsBaseInfo.feeCalculationMethod,
                apiAccountId: x.logisticsBaseInfo.apiAccountId,
                loading: false,
              };
            });
          } else {
            logistics.logisticsSchemeList = [];
            logistics.logisticsSchemeExhibitionList = [];
          }
          _funcGetExhibitionScheme();

          logistics.loading = false;
        })
        .catch((r) => {
          logistics.loading = false;
        });
    };

    //切换选择物流tabs事件（选择table和colomns数据）
    const handleChangeLogisticsTab = () => {


      logistics.logisticsSchemeExhibitionList =
        logistics.logisticsSchemeList?.filter(
          (item) => item.calculationMethod === logistics.calculationMethod
        );

      if (logistics.logisticsSchemeList?.length) {
        // 如果只有（物流或卡车中的）一种方案就不显示tab
        if (
          logistics.logisticsSchemeExhibitionList?.length === 0 ||
          logistics.logisticsSchemeExhibitionList?.length ===
            logistics.logisticsSchemeList?.length
        ) {
          logistics.isShowLogisticsTabs = false;
        } else {
          logistics.isShowLogisticsTabs = true;
        }

        if (logistics.logisticsSchemeExhibitionList?.length === 0) {
          if (
            logistics.calculationMethod === feeCalculationMethodEnum.template
          ) {
            logistics.calculationMethod = feeCalculationMethodEnum.truckApi;
          } else {
            logistics.calculationMethod = feeCalculationMethodEnum.template;
          }
          logistics.logisticsSchemeExhibitionList =
            logistics.logisticsSchemeList?.filter(
              (item) => item.calculationMethod === logistics.calculationMethod
            );
        }
      } else {
        logistics.isShowLogisticsTabs = false;
      }

      //显示columns
      if (logistics.calculationMethod === feeCalculationMethodEnum.template) {
        // 物流
        logistics.logisticsSchemeExhibitionColumns = columnsLogisticsTemplate;
      } else {
        // 卡车
        logistics.logisticsSchemeExhibitionColumns = columnsLogisticsTruck;
        getSupplierList();
      }
    };

    //获得当前应当展示的物流list 和 columns
    const _funcGetExhibitionScheme = () => {
      //当前选择tab的list
      logistics.logisticsSchemeExhibitionList =
        logistics.logisticsSchemeList?.filter(
          (item) => item.calculationMethod === logistics.calculationMethod
        );

      if (logistics.logisticsSchemeList?.length) {
        //切换tba 的数据，没有就再切换回去
        if (
          logistics.logisticsSchemeExhibitionList?.length === 0 ||
          logistics.logisticsSchemeExhibitionList?.length ===
            logistics.logisticsSchemeList?.length
        ) {
          //logisticsSchemeExhibitionList 筛选出来的长度与原数组长度是否相同
          logistics.isShowLogisticsTabs = false;
        } else {
          logistics.isShowLogisticsTabs = true;
        }

        if (logistics.logisticsSchemeExhibitionList?.length === 0) {
          //如果切换tab后的list为0，就再切换回去
          if (
            logistics.calculationMethod === feeCalculationMethodEnum.template
          ) {
            logistics.calculationMethod = feeCalculationMethodEnum.truckApi;
          } else {
            logistics.calculationMethod = feeCalculationMethodEnum.template;
          }
          logistics.logisticsSchemeExhibitionList =
            logistics.logisticsSchemeList.filter(
              (item) => item.calculationMethod === logistics.calculationMethod
            );
        }
      } else {
        logistics.isShowLogisticsTabs = false;
      }

      //计算columns
      if (logistics.calculationMethod === feeCalculationMethodEnum.template) {
        // 物流
        logistics.logisticsSchemeExhibitionColumns = columnsLogisticsTemplate;
      } else {
        // 卡车
        logistics.logisticsSchemeExhibitionColumns = columnsLogisticsTruck;
        getSupplierList();
      }
    };

    const trackFeeModal = reactive({
      visible: false,
      list: [],
      quoteModel: null,
      pageData: {
        currentPageIndex: 1,
        currentPageSize: 10,
      },
      loading: false,
    });

    const columnsTrackFee = [
      {
        dataIndex: "carrierName",
        slots: {
          title: "carrier",
        },
        sorter: (a, b) => {
          return (a.carrierName || "").localeCompare(b.carrierName);
        },
        width: 250,
      },
      {
        title: () => i18n.t("logistics.price"),
        slots: {
          customRender: "priceC",
        },
        sorter: (a, b) => {
          return a.totalCharge - b.totalCharge;
        },
        width: 150,
      },
      {
        dataIndex: "transitTime",
        slots: {
          title: "aging",
          customRender: "transitTime",
        },
        sorter: (a, b) => {
          return a.transitTime - b.transitTime;
        },
        width: 100,
      },
      {
        title: "",
        slots: {
          customRender: "select",
        },
        fixed: "right",
        width: 100,
      },
    ];

    //缓存卡车下拉框数据
    var quotesCacheObj = {};
    var getCacheKey = (paras) => {
      return (
        paras.quoteTypeId +
        ";" +
        paras.serviceLevel +
        ":" +
        paras.id +
        "," +
        (paras.accessorials || []).sort().join("-")
      );
    };

    // _组合出接口需要用用Product的参数
    const _getProductDatas = (products) => {
      let productDatas = [];
      products?.forEach((x) => {
        productDatas.push({ productId: x.productId, outCount: x.outCount });
      });
      return productDatas;
    };

    //显示卡车费用modal
    const handleShowTrackFeeModal = (quoteModel) => {
      if (!quoteModel.quoteTypeId) {
        message.error(
          i18n.t("common.p0_is_required", [i18n.t("logistics.quote_type")])
        );
        return;
      }

      if (!funcIsShowServiceLevel(quoteModel)) {
        quoteModel.serviceLevel = null;
      } else {
        if (!quoteModel.serviceLevel) {
          message.error(
            i18n.t("common.p0_is_required", [i18n.t("logistics.service_level")])
          );
          return;
        }
      }

      trackFeeModal.visible = true;
      trackFeeModal.quoteModel = quoteModel;
      trackFeeModal.pageData.currentPageIndex = 1;
      _funcGetTrackLogisticsFee(quoteModel);
    };

    //获得卡车modal table数据
    const _funcGetTrackLogisticsFee = (quoteModel) => {
      var cacheKey = getCacheKey(quoteModel);
      if (quotesCacheObj[cacheKey]) {
        trackFeeModal.list = quotesCacheObj[cacheKey];
        return;
      }
      trackFeeModal.list = [];
      let productDatas = _getProductDatas(
        logistics.currentLogisticInfo?.products
      );
      let pra = {
        warehouseId: logistics.currentLogisticInfo.warehouseId,
        packMaterialId: logistics.currentLogisticInfo.packMaterialConfig.id,
        packMaterialType:logistics.currentLogisticInfo.packMaterialType,
        productDatas: productDatas,
        receiveAddressData: logistics.currentLogisticInfo.receiveAddressData,
        relationOrderNo: logistics.currentLogisticInfo.relationOrderNo,
        logisticsId: quoteModel.id,
      };

      trackFeeModal.loading = true;
      getTrackLogisticsFee({ ...pra, truckApiLogistics: quoteModel })
        .then(({ result }) => {
          if (result) {
            trackFeeModal.list = result;
            quotesCacheObj[cacheKey] = result;
          } else {
            trackFeeModal.quoteModel.currencySymbol = null;
            trackFeeModal.quoteModel.totalCharge = null;
          }
          trackFeeModal.loading = false;
        })
        .catch(() => {
          trackFeeModal.loading = false;
        });
    };

    //选择查询物流的费用
    const handleSelectedTrackFee = (record) => {
      trackFeeModal.quoteModel.quoteDetail = record;
      trackFeeModal.visible = false;
    };
      
    //选择物流(获取某个物流的详情,更新签名签收)--模板计算
    const handleSelectLogistics=(record)=>{
      if (record) {
        logistics.selectedLogisticsSchemeId = record.id;
      }

      let pra = {
        warehouseId: logistics.currentLogisticInfo.warehouseId,
        packMaterialId: logistics.currentLogisticInfo.packMaterialConfig?.id ?? null,
        logisticsId: logistics.selectedLogisticsSchemeId,
        productDatas: logistics.currentLogisticInfo.productsParameter,
        receiveAddressData: logistics.currentLogisticInfo.receiveAddressData,
        logisticsType: agentOrderLogisticsTypeEnum.seLogistics,
        packMaterialType: logistics.currentLogisticInfo.packMaterialType,
      };

       logistics.loading = true;
      getLogisticsFee({ ...pra })
        .then((res) => {
            if (
              record.calculationMethod == feeCalculationMethodEnum.template
            ) {
              logistics.logisticsSchemeExhibitionColumns = columnsLogisticsTemplate;
              
              logistics.logisticsSchemeExhibitionList = [];
              
              let r = [res.result.logistic];
              logistics.logisticsSchemeExhibitionList = r?.map((x, index) => {
                return {
                  key: index,
                  id: x.logisticsBaseInfo.id,
                  scheme: x.logisticsBaseInfo.name,
                  code: x.logisticsBaseInfo.code,
                  descriptionItems: x.logisticsBaseInfo.descriptionItems,

                  logisticsWay: x.logisticsBaseInfo.logisticsType,
                  deliveryWay: x.logisticsBaseInfo.deliveryType,

                  daysMin: x.logisticsBaseInfo.daysMin,
                  daysMax: x.logisticsBaseInfo.daysMax,
                  channelTimeType: x.logisticsBaseInfo.channelTimeType, //签收或自提 枚举

                  averagePrice: x.logisticsBaseInfo.averagePrice, //平均费
                  expressFee: x.logisticsBaseInfo.totalFeeWithoutServices, //合计费用
                  currencySymbol: x.logisticsBaseInfo.currencySymbol, //货号符号

                  chargeableWeight: gToKg(x.logisticsBaseInfo.chargeableWeight), //计费重
                  volumeWeightParam: x.logisticsBaseInfo.volumeWeightParam, //体积系数
                  chargeableVolume: cmCubicToM(
                    x.logisticsBaseInfo.chargeableVolume
                  ),
                  logisticsCalcMethod: x.logisticsBaseInfo.logisticsCalcMethod,

                  logisticsRegionInfo: x.logisticsRegionInfo,
                  // isVatRequired: x.logisticsRegionInfo.isVatRequired,
                };
              });
            } 
           logistics.isShowLogisticsTabs = false;
           logistics.loading = false;
        })
        .catch((e) => {
           logistics.loading = false;
        });

    }


    //确定物流 - 模板计算
    const handleConfirmLogistics = (record) => {
      if (record == 'template') {
        record = logistics?.logisticsSchemeExhibitionList[0];
      }
      logistics.visible = false;
      if (record.calculationMethod == feeCalculationMethodEnum.truckApi) {
        logistics.currentLogisticInfo.logistic.logisticsBaseInfo.feeCalculationMethod =
          record.calculationMethod;
        logistics.currentLogisticInfo.logistic.logisticsBaseInfo.code =
          record.code ?? null;
        logistics.currentLogisticInfo.logistic.logisticsBaseInfo.truckBaseInfo =
          {
            quoteDetailId: null,
            quoteType: { id: null },
            serviceLevel: null,
            accessorials: null,
          };
        logistics.currentLogisticInfo.logistic.logisticsBaseInfo.truckBaseInfo.quoteDetailId =
          record.quoteDetail.quoteDetailId;
        logistics.currentLogisticInfo.logistic.logisticsBaseInfo.truckBaseInfo.quoteType.id =
          record.quoteTypeId;
        logistics.currentLogisticInfo.logistic.logisticsBaseInfo.truckBaseInfo.serviceLevel =
          record.serviceLevel;
        logistics.currentLogisticInfo.logistic.logisticsBaseInfo.truckBaseInfo.accessorials =
          record?.accessorials;
      } else {
        let vatCode = null;
        if (
          logistics.currentLogisticInfo.logistic.logisticsRegionInfo.vatCode
        ) {
          vatCode =
            logistics.currentLogisticInfo.logistic.logisticsRegionInfo.vatCode;
        }

        logistics.currentLogisticInfo.logistic.logisticsBaseInfo.feeCalculationMethod =
          record.calculationMethod;
        logistics.currentLogisticInfo.logistic.logisticsRegionInfo =
          record.logisticsRegionInfo; // 选了ddp和签字签收,这个对象里面才有这个字段,不然没有这个字段.
        logistics.currentLogisticInfo.logistic.logisticsRegionInfo.vatCode =
          vatCode; //不清除原来的vat

        logistics.currentLogisticInfo.logistic.logisticsBaseInfo.id = record.id;
        logistics.currentLogisticInfo.logistic.logisticsBaseInfo.name =
          record.scheme;
        logistics.currentLogisticInfo.logistic.logisticsBaseInfo.code =
          record.code ?? null;
        logistics.currentLogisticInfo.logistic.logisticsBaseInfo.deliveryType =
          record.deliveryWay;

        //一定需要,后端根据这个来判断是卡车还是模板物流
        logistics.currentLogisticInfo.logistic.logisticsBaseInfo.truckBaseInfo =
          null;
      }

      funcValidationTableData();
    };
  
    //-----------------重新选择物流 end------------------------------------------------------------------------

    //-----------------错误消息 start-------------
    
    const errorMsgModal = reactive({
      visible: false,
      errObjs:null,
      succeedCount:0,
      failCount:0,
      })
    //-----------------错误消息 end-------------


    return {
      data,
      logistics,
      columnsImport,
      innerColumnsImport,
      logisticsTypeEnum,
      feeCalculationMethodEnum,
      deliveryWayEnum,
      packMaterialTypeEnum,
      getAddress,
      getText, //获得材料类型的中英文text
      getErrorMsgKey,

      handleParseFile,
      hanldeDownloadTemplateFile,
      hanldeDeleteData,
      hanldeDeleteProduct,

      //包装材料
      hanldeChangeMaterialType,
      handelChangeSelectPackagingType,
      getPackingMaterials,

      //修改物流
      trackFeeModal,
      columnsTrackFee,
      hanldeShowLogisticsModal,
      //获取某个物流的详情
      handleSelectLogistics,
      //确定选择物流
      handleConfirmLogistics,

      handleChangeLogisticsTab,
      funcIsShowServiceLevel,
      handleShowTrackFeeModal,
      getAccessorialsName,
      handleSelectedTrackFee,

      //函数判断 导入按钮的disblae
      funcWhetherCanImport,
      hanldeShowImporMessage,
      hanldeImportConfirm,

      //修改地址
      addressModal,
      handleAlterAddress,
      hanldeShowAddressModal,

      //更改sesku
      productModal,
      hanldeSearchProducts,
      refSearchProduct,
      modalColumns,
      handlePage,
      handleShowModalProduct,
      handleAddProductToList,
      errorMsgModal
    };
  },
};
</script>

<style lang="less" scoped>
.messageCard {
	overflow-y: scroll;
  max-height:300px;
}
.messageCard::-webkit-scrollbar {
    width: 4px;
}
.messageCard::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0,0,0,0.2);
}
.messageCard::-webkit-scrollbar-track {
    border-radius: 0;
    background: rgba(0,0,0,0.1);
}

</style>
