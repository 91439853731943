import client from "../../client";
import siteOptions from "@/siteconfigs/index";
import urlHelper from "@/utils/urlHelper.js";

const Api = {
  joinDownloadTemplateUrl: "/api/WarehouseService/AgentOrder/GenerateTemplate",
  parseFile: "/api/WarehouseService/AgentOrder/ParseExcelData",
  importConfirm: "/api/WarehouseService/AgentOrder/BatchOrderAdd",
  validationTableData:"/api/WarehouseService/AgentOrder/ValidImport"
};

export const joinDownloadTemplateUrl = () => {
  let url = urlHelper.combineURLs(siteOptions.baseURL, Api.joinDownloadTemplateUrl);
  return url;
};

export const parseFile = (data) => {
  return client.request({
    url: Api.parseFile,
    data: data,
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const importConfirm = (data) => {
  return client.request({
    url: Api.importConfirm,
    data: data,
    method: "post",
  });
};

export const validationTableData = (data) => {
  return client.request({
    url: Api.validationTableData,
    data: data,
    method: "post",
  });
};

