<template>
  <a-modal
    v-model:visible="visible"
    :title="$t('logistics.select_quote')"
    :centered="true"
    :mask-closable="false"
    :footer="null"
    width="auto"
  >
    <a-table
      :columns="columns"
      :data-source="list"
      :loading="loading"
      size="small"
      :scroll="{ x: 500, y: 400 }"
      :pagination="{
        defaultPageSize: pageData.currentPageSize,
        hideOnSinglePage: true,
        current: pageData.currentPageIndex,
        position: 'bottom',
      }"
      :rowKey="
        (record, index) => {
          return index;
        }
      "
      @change='(page) => {pageData.currentPageIndex = page.current;}'
    >
      <template #transitTimeTitle>
        {{ $t('logistics.aging') }}(days)
      </template>
      <template #price="{ record }">
        {{ $filters.formatCurrencyAmount(record.currencySymbol, record.totalCharge, false) }}
      </template>
      <template #options="{ record }">
        <a-button type="ghost" size="small" @click="handleClick(record)">{{ $t('common.select') }}</a-button>
      </template>
    </a-table>
  </a-modal>
</template>

<script>
import { defineComponent, reactive, toRefs, nextTick } from 'vue'
import { Button, Modal, Table } from 'ant-design-vue'
import { useI18n } from "vue-i18n/index"
import { getTruckFee } from "@/api/modules/transportation/index";

export default defineComponent({
  components: {
    AModal: Modal,
    ATable: Table,
    AButton: Button,
  },
  setup (props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" })

    const state = reactive({
      visible: false,
      loading: false,
      pageData: {
        currentPageIndex: 1,
        currentPageSize: 10,
      },
      logisticsId: null,
      list: []
    })

    const columns = [
      {
        dataIndex: "carrierName",
        width: 100,
        title: () => vueI18n.t('logistics.carrier'),
        sorter: (a, b) => {
          if (a.carrierName > b.carrierName) {
            return 1
          }
          if (a.carrierName < b.carrierName) {
            return -1
          }
          return 0
        }
      },
      {
        width: 100,
        title: () => vueI18n.t('logistics.price'),
        slots: {
          customRender: 'price'
        },
        sorter: (a, b) => {
          return a.totalCharge - b.totalCharge
        }
      },
      {
        dataIndex: "transitTime",
        width: 100,
        slots: {
          title: 'transitTimeTitle'
        },
        sorter: (a, b) => {
          return a.transitTime - b.transitTime
        }
      },
      {
        width: 60,
        slots: {
          customRender: 'options'
        }
      },
    ]

    // 卡车报价列表缓存
    const truckQuotedPriceCache = {}

    const getTruckQuotedPriceCacheKey = (params) => {
      return params.logisticsId + ':'
             + params.truckApiLogistics.quoteTypeId + ':'
             + params.truckApiLogistics.serviceLevel
             + (params.truckApiLogistics.accessorials || []).sort().join('-')
    }

    const getTruckQuotedPriceCache = async (params) => {
      try {
        let cacheKey = getTruckQuotedPriceCacheKey(params)
        if (truckQuotedPriceCache[cacheKey]) {
          return Promise.resolve(truckQuotedPriceCache[cacheKey])
        }
        const data = Object.assign({}, params)
        let { result } = await getTruckFee(data)
        truckQuotedPriceCache[cacheKey] = result
        return Promise.resolve(result)
      } catch (error) {
        return Promise.reject(error)
      }
    }

    const handleParseTime = (num) => {
      let number = Number.parseInt(num)
      return Number.isNaN(number) ? num : number
    }

    const handleClick = (record) => {
      emit('confirm', { logisticsId: state.logisticsId, record })
      nextTick(() => {
        state.visible = false
      })
    }

    const open = async (data) => {
      state.visible = true
      state.logisticsId = data.logisticsId
      state.list = []
      try {
        state.loading = true
        let list = await getTruckQuotedPriceCache(data)
        state.list = list.map(item => {
          item.transitTime = handleParseTime(item.transitTime)
          return item
        })
        state.pageData.currentPageIndex = 1
      } catch (error) {
      } finally {
        state.loading = false
      }
    }

    return {
      ...toRefs(state),
      columns,
      handleClick,
      open,
    }
  }
})
</script>

<style scoped>

</style>